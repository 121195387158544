import React, { FC } from 'react';
import { InspirationFilter } from 'components';

interface InspirationFilterListProps {
  Filters: {
    title: string;
    options: string[];
  }[];
  setPageFilter: any;
}
const InspirationFilterList: FC<InspirationFilterListProps> = ({
  Filters,
  setPageFilter,
}) => {
  return (
    <>
      <h3 className="inspiration-section-filter-heading">Filter</h3>
      <ul className="inspiration-section-filter-list">
        {Filters.map(filter => {
          return (
            <InspirationFilter
              Filter={filter}
              key={filter.title}
              setPageFilter={setPageFilter}
            />
          );
        })}
      </ul>
    </>
  );
};

export { InspirationFilterList };
