import React, { FC } from 'react';
import 'sass/style.scss';
import { Header, Footer } from 'components';
import { LayoutQuery } from 'Queries/Layout';

export interface LayoutProps {
  title: string | null;
  children: JSX.IntrinsicElements['div'];
  youtubeLink?: string;
  instagramLink?: string;
  linkedinLink?: string;
  facebookLink?: string;
  socialMediaTitle?: string;
  quotation?: string;
  mailLink?: string;
  landLineNumber?: string;
  showBookAppointmentForm?: boolean;
  icon?: JSX.IntrinsicElements['svg'] | string;
}

const Layout: FC<LayoutProps> = ({ children }: LayoutProps) => {
  const data = LayoutQuery();
  const {
    strapiGlobal: {
      footer: {
        address,
        landLineNumber,
        socialMediaLink: {
          instagramLink,
          linkedinLink,
          facebookLink,
          youtubeLink,
          title,
          mailLink,
        },
      },
    },
    footerMenu: { edges },
  } = data;

  return (
    <>
      <Header mailLink={mailLink} landLineNumber={landLineNumber} />
      <main className="main">{children}</main>
      <Footer
        address={address}
        youtubeLink={youtubeLink}
        instagramLink={instagramLink}
        linkedinLink={linkedinLink}
        facebookLink={facebookLink}
        socialMediaTitle={title}
        mailLink={mailLink}
        landLineNumber={landLineNumber}
        menu={edges}
      />
    </>
  );
};
export default Layout;
