import React, { FC } from 'react';
import { LionIcon } from 'assets/tsx/lion-icon';
import { MenuLogo } from 'assets/tsx/menu-logo';
import { MenuQuery } from 'Queries/Menu';
import { MenuSlideInLeft } from 'components';

const Menu: FC = () => {
  const data = MenuQuery();
  const FilteredMenu = data?.menu?.edges[0]?.node?.mainNav.filter(
    (navItem: any) => {
      return navItem.slug !== '/' && navItem.slug !== 'faq';
    }
  );
  const menuIcons = {
    MenuLogo,
    LionIcon,
  };
  return (
    <>
      <MenuSlideInLeft
        menuIcons={menuIcons}
        menuContent="Realising moments of significance"
        menus={FilteredMenu}
      />
    </>
  );
};

export { Menu };
