export const splitText = (st: string) => {
  return st.split(' ');
};
export const splitChars = (st: string) => {
  return st.split('');
};
export const capitalize = (st: string) => {
  if (typeof st !== 'string') return '';
  return st.charAt(0).toUpperCase() + st.slice(1);
};
