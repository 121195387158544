import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface TextSlideOutLeftProps {
  children: React.ReactNode;
}

export const TextSlideOutLeft: FC<TextSlideOutLeftProps> = ({ children }) => {
  const PeopleNameRef = useRef() as React.MutableRefObject<HTMLHeadingElement>;
  useEffect(() => {
    gsap.to(PeopleNameRef.current, {
      x: 20,
      scrollTrigger: {
        trigger: PeopleNameRef.current,
        start: 'top center',
        end: 'bottom top',
        toggleActions: 'play none none reverse',
      },
    });
  }, []);
  return (
    <>
      <h3 className="people-list-item-detail-name" ref={PeopleNameRef}>
        {children}
      </h3>
    </>
  );
};
