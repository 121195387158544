import {
  BOOK_APPOINTMENT_SUCESS,
  EMAIL_INVALID_RESET_APP,
  EMAIL_REQUIRED_RESET_APP,
  EMAIL_INVALID_APP,
  EMAIL_REQUIRED_APP,
  NAME_REQUIRED_RESET_APP,
  NAME_REQUIRED_APP,
  NAME_INVALID_RESET_APP,
  NAME_INVALID_APP,
  PHONE_NUMBER_REQUIRED_APP,
  PHONE_NUMBER_INVALID_RESET_APP,
  PHONE_NUMBER_INVALID_APP,
  PHONE_NUMBER_REQUIRED_RESET_APP,
  BOOK_APPOINTMENT_FAILURE,
  MESSAGE_REQUIRED_APP,
  MESSAGE_INVALID_RESET_APP,
  MESSAGE_INVALID_APP,
  MESSAGE_REQUIRED_RESET_APP,
  HEARABOUTUS_REQUIRED_APP,
  HEARABOUTUS_REQUIRED_RESET_APP,
  VALIDATION_RESET,
} from 'Redux/actionTypes';

type InitialStateType = {
  isSubmitSucess: boolean;
  isAppointmentNameRequired: boolean;
  isAppointmentNameInvalid: boolean;
  isAppointmentEmailRequired: boolean;
  isAppointmentEmailInvalid: boolean;
  isAppointmentPhoneNumberRequired: boolean;
  isAppointmentPhoneNumberInvalid: boolean;
  isAppointmentMessageRequired: boolean;
  isAppointmentMessageInvalid: boolean;
  isAppointmentheardAboutUsRequired: boolean;
  isSubmitFailure: boolean;
};

const InitialState: InitialStateType = {
  isSubmitSucess: false,
  isAppointmentNameRequired: false,
  isAppointmentNameInvalid: false,
  isAppointmentEmailRequired: false,
  isAppointmentEmailInvalid: false,
  isAppointmentPhoneNumberRequired: false,
  isAppointmentPhoneNumberInvalid: false,
  isAppointmentMessageRequired: false,
  isAppointmentMessageInvalid: false,
  isAppointmentheardAboutUsRequired: false,
  isSubmitFailure: false,
};

const BookAppointmentReducer: any = (state = InitialState, action: any) => {
  switch (action.type) {
    case BOOK_APPOINTMENT_SUCESS:
      return {
        ...state,
        isSubmitSucess: true,
        isAppointmentNameRequired: false,
        isAppointmentNameInvalid: false,
        isAppointmentEmailRequired: false,
        isAppointmentEmailInvalid: false,
        isAppointmentPhoneNumberRequired: false,
        isAppointmentPhoneNumberInvalid: false,
        isAppointmentMessageRequired: false,
        isAppointmentMessageInvalid: false,
        isAppointmentheardAboutUsRequired: false,
        isSubmitFailure: false,
      };
    case NAME_REQUIRED_APP:
      return { ...state, isAppointmentNameRequired: true };
    case NAME_REQUIRED_RESET_APP:
      return { ...state, isAppointmentNameRequired: false };
    case NAME_INVALID_APP:
      return { ...state, isAppointmentNameInvalid: true };
    case NAME_INVALID_RESET_APP:
      return { ...state, isAppointmentNameInvalid: false };
    case EMAIL_REQUIRED_APP:
      return { ...state, isAppointmentEmailRequired: true };
    case EMAIL_REQUIRED_RESET_APP:
      return { ...state, isAppointmentEmailRequired: false };
    case EMAIL_INVALID_APP:
      return { ...state, isAppointmentEmailInvalid: true };
    case EMAIL_INVALID_RESET_APP:
      return { ...state, isAppointmentEmailInvalid: false };
    case PHONE_NUMBER_REQUIRED_APP:
      return { ...state, isAppointmentPhoneNumberRequired: true };
    case PHONE_NUMBER_REQUIRED_RESET_APP:
      return { ...state, isAppointmentPhoneNumberRequired: false };
    case PHONE_NUMBER_INVALID_APP:
      return { ...state, isAppointmentPhoneNumberInvalid: true };
    case PHONE_NUMBER_INVALID_RESET_APP:
      return { ...state, isAppointmentPhoneNumberInvalid: false };
    case MESSAGE_REQUIRED_APP:
      return { ...state, isAppointmentMessageRequired: true };
    case MESSAGE_REQUIRED_RESET_APP:
      return { ...state, isAppointmentMessageRequired: false };
    case MESSAGE_INVALID_APP:
      return { ...state, isAppointmentMessageInvalid: true };
    case MESSAGE_INVALID_RESET_APP:
      return { ...state, isAppointmentMessageInvalid: false };
    case HEARABOUTUS_REQUIRED_APP:
      return { ...state, isAppointmentheardAboutUsRequired: true };
    case HEARABOUTUS_REQUIRED_RESET_APP:
      return { ...state, isAppointmentheardAboutUsRequired: false };

    case BOOK_APPOINTMENT_FAILURE:
      return {
        ...state,
        isSubmitFailure: true,
        isSubmitSucess: false,
        isAppointmentNameRequired: false,
        isAppointmentNameInvalid: false,
        isAppointmentEmailRequired: false,
        isAppointmentEmailInvalid: false,
        isAppointmentPhoneNumberRequired: false,
        isAppointmentPhoneNumberInvalid: false,
        isAppointmentMessageRequired: false,
        isAppointmentMessageInvalid: false,
        isAppointmentheardAboutUsRequired: false,
      };
    case VALIDATION_RESET:
      return InitialState;
    default:
      return state;
  }
};
export { BookAppointmentReducer };
