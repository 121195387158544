import React, { FC, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import Draggable from 'gsap/dist/Draggable';

gsap.registerPlugin(Draggable);

interface SliderAnimationProps {
  children: any;
  className?: string;
}

export const SliderAnimation: FC<SliderAnimationProps> = ({
  children,
  className,
}) => {
  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [Width, setWidth] = useState(0);
  const Style = {
    width: Width,
  } as React.CSSProperties;
  useEffect(() => {
    const scaleValue = window.innerWidth < 768 ? 0.8 : 0.95;
    const durationValue = window.innerWidth < 768 ? 1 : 0.5;
    const carouselWidth = document.querySelectorAll('.home-page-slider-item');
    const slider = document.querySelector('.home-page-slider');
    let totalWidth = 0;
    carouselWidth.forEach((item: any) => {
      totalWidth += item.clientWidth;
    });
    setWidth(totalWidth);
    if (slider !== null) {
      const sliderWidth = slider.offsetWidth;
      Draggable.create(wrapperRef.current, {
        type: 'x',
        throwProps: true,
        bounds: {
          minX: -totalWidth + sliderWidth,
          minY: 0,
          maxX: 0,
          maxY: 0,
        },
        onPress() {
          gsap.to('.home-page-slider', {
            duration: 0.5,
            scale: scaleValue,
          });
        },
        onRelease() {
          gsap.to('.home-page-slider', {
            duration: durationValue,
            ease: 'power1.Out',
            scale: 1,
          });
        },
      });
    }
  }, []);

  return (
    <div ref={wrapperRef} className={className ?? className} style={Style}>
      {children}
    </div>
  );
};
