/* eslint-disable no-console */
import React, { FC, useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import Img from 'gatsby-image';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import RightArrow from 'assets/svg/arrow-right.svg';
import LeftArrow from 'assets/svg/arrow-left.svg';
import {
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookShareButton,
  FacebookIcon,
  EmailIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

interface InspirationGalleryModalProp {
  ModalImageIndex: any;
  FilteredInspirationImages: {
    node: {
      strapiId: number;
      title: string;
      image: {
        childImageSharp: {
          fluid: {
            base64: string;
            aspectRatio: number;
            src: string;
            srcSet: string;
            sizes: string;
          };
        };
      };
    };
  }[];
  isModalOpen: any;
  setisModalOpen: any;
  setModalImageIndex: any;
}

const InspirationGalleryModal: FC<InspirationGalleryModalProp> = ({
  ModalImageIndex,
  isModalOpen,
  setisModalOpen,
  FilteredInspirationImages,
  setModalImageIndex,
}) => {
  const handleModalClose = () => {
    setisModalOpen(false);
    setModalImageIndex(null);
  };
  const handlePrevRequest = () => {
    setModalImageIndex(
      (ModalImageIndex - 1 + FilteredInspirationImages.length) %
        FilteredInspirationImages.length
    );
  };
  const handleNextRequest = () => {
    setModalImageIndex(
      (ModalImageIndex + 1) % FilteredInspirationImages.length
    );
  };
  const [location, setlocation] = useState('');
  useEffect(() => {
    setlocation(window.location.origin);
  }, []);
  const shareUrl =
    FilteredInspirationImages[ModalImageIndex] &&
    `${location}/gallery/${FilteredInspirationImages[ModalImageIndex].node.title
      .replace(/[^a-zA-Z\d:]/g, '-')
      .toLowerCase()}-${
      FilteredInspirationImages[ModalImageIndex].node.strapiId
    }`;

  const shareTitle =
    FilteredInspirationImages[ModalImageIndex] &&
    `${FilteredInspirationImages[ModalImageIndex].node.title}`;
  return (
    <div>
      <ReactModal
        closeTimeoutMS={1000}
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        ariaHideApp={false}
        portalClassName="ReactModalPortal inspiration-gallery-modal"
      >
        <button
          type="button"
          onClick={() => handleModalClose()}
          className="modal-close"
        >
          <span>&#x2715;</span>
        </button>
        <button
          className="modal-btn prev"
          type="button"
          onClick={handlePrevRequest}
        >
          <LeftArrow />
        </button>
        <div className="modal-img-container">
          {ModalImageIndex !== null && (
            <Zoom>
              <Img
                fluid={
                  FilteredInspirationImages[ModalImageIndex].node.image
                    .childImageSharp.fluid
                }
              />
            </Zoom>
          )}
          <div className="social-media-links">
            <FacebookShareButton url={shareUrl} quote={shareTitle}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={shareTitle}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton url={shareUrl} title={shareTitle}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <EmailShareButton url={shareUrl} subject={shareTitle}>
              <EmailIcon size={32} round />
            </EmailShareButton>
            <WhatsappShareButton url={shareUrl} title={shareTitle}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
        </div>
        <button
          type="button"
          className="modal-btn next"
          onClick={handleNextRequest}
        >
          <RightArrow />
        </button>
      </ReactModal>
    </div>
  );
};
export { InspirationGalleryModal };
