import { graphql, useStaticQuery } from 'gatsby';

const HeroBannerQuery = () => {
  return useStaticQuery(graphql`
    query HeroBannerQuery {
      HomeBanner: allStrapiHomePage {
        edges {
          node {
            heroBanner {
              banner {
                id
                title
                image {
                  publicURL
                  extension
                  childImageSharp {
                    fluid(maxWidth: 3500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              bannerTitle {
                title
                id
              }
            }
          }
        }
      }
    }
  `);
};

export { HeroBannerQuery };
