import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const SlideInZoomTitle: FC = ({ children }) => {
  const wordsRef = useRef() as React.MutableRefObject<HTMLSpanElement>;

  const HeadingSpanStyle = {
    display: 'block',
  } as React.CSSProperties;

  useEffect(() => {
    const TitleAnimation = window.innerWidth < 768 ? 170 : 110;
    gsap.fromTo(
      wordsRef.current,
      {
        transform: 'scale(2)',
        opacity: 0,
      },
      {
        duration: 1,
        transform: 'scale(1)',
        opacity: 1,
        scrollTrigger: {
          trigger: wordsRef.current,
          start: `top center+=${TitleAnimation}`,
        },
      }
    );
  }, []);

  return (
    <span ref={wordsRef} aria-hidden style={HeadingSpanStyle}>
      {children}
    </span>
  );
};
