import React, { FC, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import BackgroundImage from 'gatsby-background-image-es5';
import { VideoPlayer } from 'components';

interface BannerPropType {
  BannerLength: number;
  PageBanner: {
    banner: {
      id: string;
      image: {
        publicURL: string;
        extension: string;
        childImageSharp: {
          fluid: {
            base64: string;
            aspectRatio: number;
            src: string;
            srcSet: string;
            sizes: string;
          };
        };
      };
    }[];
  };
}

const Banner: FC<BannerPropType> = ({ PageBanner, BannerLength }) => {
  const [current, setcurrent] = useState(0);
  const [dot, setdot] = useState(false);
  const timeref = useRef(null) as React.MutableRefObject<null | any>;
  const sliderLength = BannerLength;
  const goNext = () => {
    setcurrent(next => (next === sliderLength - 1 ? 0 : next + 1));
  };
  const resetTimeout = () => {
    if (timeref.current) {
      clearTimeout(timeref.current);
    }
  };
  useEffect(() => {
    resetTimeout();
    timeref.current = setInterval(goNext, 12000);
    if (sliderLength > 1) {
      setdot(true);
    } else {
      setdot(false);
    }
    return () => {
      resetTimeout();
    };
  }, [current]);
  return (
    <>
      <div className="hero-banner-slider-wrapper">
        {PageBanner?.banner.map((banner: any, index: number) => {
          return (
            <div
              className={classNames('hero-banner-image', {
                show: current === index,
              })}
              key={banner.id}
            >
              {banner?.image?.extension &&
                banner?.image?.extension === 'mp4' ? (
                <VideoPlayer Src={banner?.image?.publicURL} />
              ) : (
                <BackgroundImage
                  className="hero-banner-image-items"
                  fluid={banner?.image?.childImageSharp?.fluid}
                />
              )}
              <div className="hero-banner-title-container">
                <div className="hero-banner-content">
                  <div className="hero-banner-content-title">
                    {banner.title}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {dot && (
        <div className="hero-banner-nav-wrap">
          <ul className="hero-banner-nav">
            {PageBanner?.banner.map((bannerImage: any, index: number) => {
              return (
                <li
                  className={classNames({
                    show: current === index,
                  })}
                  key={bannerImage.id}
                >
                  <button
                    type="button"
                    className="hero-banner-button"
                    aria-label={`Go to ${index}`}
                    onClick={() => {
                      setcurrent(index);
                    }}
                  >
                    <span className="hero-banner-dots" />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export { Banner };
