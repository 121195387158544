import { useStaticQuery, graphql } from 'gatsby';

const LayoutQuery = () => {
  return useStaticQuery(graphql`
    query siteDataQuery {
      strapiGlobal {
        siteName
        defaultSeo {
          metaDescription
          metaTitle
          shareImage {
            publicURL
          }
        }
        footer {
          landLineNumber
          address
          socialMediaLink {
            facebookLink
            id
            instagramLink
            linkedinLink
            youtubeLink
            mailLink
            title
          }
        }
        bookAnAppointment {
          formSuccessMessage
          formFailureMessage
          title
        }

        sitePopup {
          visible
          title
          summary
        }
      }
      site {
        siteMetadata {
          title
          description
          bookAppointmentURL
          contactUsURL
        }
      }
      footerMenu: allStrapiGlobal {
        edges {
          node {
            mainNav {
              title
              slug
              id
            }
          }
        }
      }
      bookappointmentform: strapiContactUsPage {
        contactForm {
          contactQuery {
            id
            title
          }
          heardAboutUs {
            id
            value
          }
          heardAboutUsTitle
          queryTitle
          buttonText
          title
          formFailureMessage
          formSuccessMessage
        }
      }
    }
  `);
};
export { LayoutQuery };
