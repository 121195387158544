import { combineReducers } from 'redux';
import { NavReducer } from 'Redux/reducers/navReducer';
import { BookAppointmentReducer } from 'Redux/reducers/bookAppointmentReducer';
import { ContactUsReducer } from 'Redux/reducers/contactUsReducer';

export default combineReducers({
  NavReducer,
  BookAppointmentReducer,
  ContactUsReducer,
});
