import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Img from 'gatsby-image';

gsap.registerPlugin(ScrollTrigger);

interface SlideInUpRightProps {
  title: string;
  slideCount: string;
  dynamicClass: string;
  description: string;
  classname: string;
  image: {
    childImageSharp: {
      fluid: {
        base64: string;
        aspectRatio: number;
        src: string;
        srcSet: string;
        sizes: string;
      };
    };
  };
  id: number;
}

export const SlideInUpRight: FC<SlideInUpRightProps> = ({
  slideCount,
  title,
  image,
  dynamicClass,
  classname,
  description,
  id,
}) => {
  const SliderImageRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const SliderContentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useEffect(() => {
    gsap.fromTo(
      SliderImageRef.current,
      { x: 200, y: 200, opacity: 0 },
      {
        x: 0,
        y: 0,
        rotateX: 0,
        opacity: 1,
        duration: 1.5,
        scrollTrigger: {
          trigger: SliderImageRef.current,
          toggleActions: 'play none none reverse',
          start: 'top center+=100',
        },
      }
    );
    gsap.fromTo(
      SliderContentRef.current,
      { x: -200, y: 200, opacity: 0 },
      {
        x: 0,
        y: 0,
        rotateX: 0,
        opacity: 1,
        duration: 1.5,
        scrollTrigger: {
          trigger: SliderImageRef.current,
          toggleActions: 'play none none reverse',
          start: 'top center+=100',
        },
      }
    );
  }, []);
  return (
    <>
      <div className={`${classname} ${dynamicClass}`} key={id}>
        <h4 className={`${classname}-title`}>
          <div>
            <span>{slideCount}.</span>
            <p>{title}</p>
          </div>
        </h4>
        <div className={`${classname}-image`} ref={SliderImageRef}>
          <Img fluid={image.childImageSharp.fluid} alt={title} />
        </div>
        <div className={`${classname}-description`} ref={SliderContentRef}>
          <p> {description}</p>
        </div>
      </div>
    </>
  );
};
