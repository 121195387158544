import {
  TOOGLE_NAV,
  BOOK_APPOINTMENT_INIT,
  BOOK_APPOINTMENT_SUBMITTED,
  BOOK_APPOINTMENT_SUCESS,
  BOOK_APPOINTMENT_FAILURE,
  CONTACT_US_INIT,
  CONTACT_US_SUBMITTED,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
  NAME_REQUIRED,
  NAME_REQUIRED_RESET,
  NAME_INVALID,
  NAME_INVALID_RESET,
  NAME_REQUIRED_RESET_APP,
  NAME_REQUIRED_APP,
  NAME_INVALID_RESET_APP,
  NAME_INVALID_APP,
  EMAIL_REQUIRED,
  EMAIL_INVALID,
  EMAIL_REQUIRED_RESET,
  EMAIL_INVALID_RESET,
  EMAIL_INVALID_RESET_APP,
  EMAIL_REQUIRED_RESET_APP,
  EMAIL_INVALID_APP,
  EMAIL_REQUIRED_APP,
  MESSAGE_REQUIRED,
  MESSAGE_REQUIRED_RESET,
  MESSAGE_INVALID,
  MESSAGE_INVALID_RESET,
  PHONE_NUMBER_REQUIRED_APP,
  PHONE_NUMBER_INVALID_RESET_APP,
  PHONE_NUMBER_INVALID_APP,
  PHONE_NUMBER_REQUIRED_RESET_APP,
  PHONE_NUMBER_REQUIRED,
  PHONE_NUMBER_INVALID_RESET,
  PHONE_NUMBER_INVALID,
  PHONE_NUMBER_REQUIRED_RESET,
  HEARABOUTUS_REQUIRED,
  HEARABOUTUS_REQUIRED_RESET,
  VALIDATION_RESET,
  MESSAGE_REQUIRED_APP,
  MESSAGE_INVALID_RESET_APP,
  MESSAGE_INVALID_APP,
  MESSAGE_REQUIRED_RESET_APP,
  HEARABOUTUS_REQUIRED_APP,
  HEARABOUTUS_REQUIRED_RESET_APP,
} from 'Redux/actionTypes';
import axios from 'axios';

export const toggleNav = () => ({
  type: TOOGLE_NAV,
});

export const initBookAppointment = () => ({
  type: BOOK_APPOINTMENT_INIT,
});

interface submitBookAppointmentProps {
  name: string;
  email: string;
  phonenumber: string;
  bookAppointmentURL: string;
  bookanAppointmentMessage: string;
  select: string;
  heardAboutus: string;
}

export const submitBookAppointment = ({
  name,
  email,
  phonenumber,
  bookAppointmentURL,
  bookanAppointmentMessage,
  select,
  heardAboutus,
}: submitBookAppointmentProps) => {
  const errors: any = {};
  if (!name) {
    errors.nameRequired = true;
  } else if (name.trim().length < 2) {
    errors.nameInvalid = true;
  }

  if (!email) {
    errors.emailRequired = true;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.emailInvalid = true;
  }
  if (!phonenumber) {
    errors.phonenumberRequired = true;
  } else if (!/^[0-9]{10}$/.test(phonenumber)) {
    errors.phonenumberInvalid = true;
  }
  if (!bookanAppointmentMessage) {
    errors.message = true;
  } else if (bookanAppointmentMessage.trim().length < 1) {
    errors.messageInvalid = true;
  }

  if (heardAboutus === '') {
    errors.heardAboutusRequired = true;
  }

  return (dispatch: any) => {
    if (Object.keys(errors).length === 0) {
      dispatch({ type: BOOK_APPOINTMENT_SUBMITTED });
      axios
        .post(bookAppointmentURL, {
          name,
          email,
          phonenumber,
          bookanAppointmentMessage,
          select,
          heardAboutus,
        })
        .then(response => {
          if (response) {
            // TODO get message from server
            dispatch({ type: BOOK_APPOINTMENT_SUCESS });
          }
          // TODO error handling
        })
        .catch(() => {
          dispatch({ type: BOOK_APPOINTMENT_FAILURE });
        });
    } else {
      if (errors.nameRequired) {
        dispatch({ type: NAME_REQUIRED_APP });
        dispatch({ type: NAME_INVALID_RESET_APP });
      } else if (errors.nameInvalid) {
        dispatch({ type: NAME_INVALID_APP });
        dispatch({ type: NAME_REQUIRED_RESET_APP });
      } else {
        dispatch({ type: NAME_REQUIRED_RESET_APP });
        dispatch({ type: NAME_INVALID_RESET_APP });
      }

      if (errors.emailRequired) {
        dispatch({ type: EMAIL_REQUIRED_APP });
        dispatch({ type: EMAIL_INVALID_RESET_APP });
      } else if (errors.emailInvalid) {
        dispatch({ type: EMAIL_INVALID_APP });
        dispatch({ type: EMAIL_REQUIRED_RESET_APP });
      } else {
        dispatch({ type: EMAIL_REQUIRED_RESET_APP });
        dispatch({ type: EMAIL_INVALID_RESET_APP });
      }

      if (errors.phonenumberRequired) {
        dispatch({ type: PHONE_NUMBER_REQUIRED_APP });
        dispatch({ type: PHONE_NUMBER_INVALID_RESET_APP });
      } else if (errors.phonenumberInvalid) {
        dispatch({ type: PHONE_NUMBER_INVALID_APP });
        dispatch({ type: PHONE_NUMBER_REQUIRED_RESET_APP });
      } else {
        dispatch({ type: PHONE_NUMBER_REQUIRED_RESET_APP });
        dispatch({ type: PHONE_NUMBER_INVALID_RESET_APP });
      }

      if (errors.message) {
        dispatch({ type: MESSAGE_REQUIRED_APP });
        dispatch({ type: MESSAGE_INVALID_RESET_APP });
      } else if (errors.messageInvalid) {
        dispatch({ type: MESSAGE_INVALID_APP });
        dispatch({ type: MESSAGE_REQUIRED_RESET_APP });
      } else {
        dispatch({ type: MESSAGE_INVALID_RESET_APP });
        dispatch({ type: MESSAGE_REQUIRED_RESET_APP });
      }

      if (errors.heardAboutusRequired) {
        dispatch({ type: HEARABOUTUS_REQUIRED_APP });
      } else {
        dispatch({ type: HEARABOUTUS_REQUIRED_RESET_APP });
      }
    }
  };
};

export const initContactUs = () => ({
  type: CONTACT_US_INIT,
});

interface submitContactUsProps {
  name: string;
  email: string;
  phonenumber: string;
  contactUsMessage: string;
  select: string;
  contactUsUrl: string;
  heardAboutus: string;
}

export const submitContactUs = ({
  name,
  email,
  phonenumber,
  contactUsMessage,
  contactUsUrl,
  select,
  heardAboutus,
}: submitContactUsProps) => {
  const errors: any = {};
  if (!name) {
    errors.nameRequired = true;
  } else if (name.trim().length < 2) {
    errors.nameInvalid = true;
  }

  if (!email) {
    errors.emailRequired = true;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.emailInvalid = true;
  }

  if (!phonenumber) {
    errors.phonenumberRequired = true;
  } else if (!/^[0-9]{10}$/.test(phonenumber)) {
    errors.phonenumberInvalid = true;
  }

  if (!contactUsMessage) {
    errors.message = true;
  } else if (contactUsMessage.trim().length < 1) {
    errors.messageInvalid = true;
  }

  if (heardAboutus === '') {
    errors.heardAboutusRequired = true;
  }

  return (dispatch: any) => {
    if (Object.keys(errors).length === 0) {
      dispatch({ type: CONTACT_US_SUBMITTED });
      axios
        .post(contactUsUrl, {
          name,
          email,
          phonenumber,
          contactUsMessage,
          select,
          heardAboutus,
        })
        .then(response => {
          if (response) {
            // TODO get message from server
            dispatch({ type: CONTACT_US_SUCCESS });
          }
          // TODO error handling
        })
        .catch(() => {
          dispatch({ type: CONTACT_US_FAILURE });
        });
    } else {
      if (errors.nameRequired) {
        dispatch({ type: NAME_REQUIRED });
        dispatch({ type: NAME_INVALID_RESET });
      } else if (errors.nameInvalid) {
        dispatch({ type: NAME_INVALID });
        dispatch({ type: NAME_REQUIRED_RESET });
      } else {
        dispatch({ type: NAME_REQUIRED_RESET });
        dispatch({ type: NAME_INVALID_RESET });
      }

      if (errors.emailRequired) {
        dispatch({ type: EMAIL_REQUIRED });
        dispatch({ type: EMAIL_INVALID_RESET });
      } else if (errors.emailInvalid) {
        dispatch({ type: EMAIL_INVALID });
        dispatch({ type: EMAIL_REQUIRED_RESET });
      } else {
        dispatch({ type: EMAIL_REQUIRED_RESET });
        dispatch({ type: EMAIL_INVALID_RESET });
      }

      if (errors.phonenumberRequired) {
        dispatch({ type: PHONE_NUMBER_REQUIRED });
        dispatch({ type: PHONE_NUMBER_INVALID_RESET });
      } else if (errors.phonenumberInvalid) {
        dispatch({ type: PHONE_NUMBER_INVALID });
        dispatch({ type: PHONE_NUMBER_REQUIRED_RESET });
      } else {
        dispatch({ type: PHONE_NUMBER_REQUIRED_RESET });
        dispatch({ type: PHONE_NUMBER_INVALID_RESET });
      }

      if (errors.message) {
        dispatch({ type: MESSAGE_REQUIRED });
        dispatch({ type: MESSAGE_INVALID_RESET });
      } else if (errors.messageInvalid) {
        dispatch({ type: MESSAGE_INVALID });
        dispatch({ type: MESSAGE_REQUIRED_RESET });
      } else {
        dispatch({ type: MESSAGE_INVALID_RESET });
        dispatch({ type: MESSAGE_REQUIRED_RESET });
      }

      if (errors.heardAboutusRequired) {
        dispatch({ type: HEARABOUTUS_REQUIRED });
      } else {
        dispatch({ type: HEARABOUTUS_REQUIRED_RESET });
      }
    }
  };
};

export const resetBookAppointment = () => {
  return (dispatch: any) => {
    dispatch({ type: VALIDATION_RESET });
  };
};
