import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const SlideInUpleftImage: FC = ({ children }) => {
  const wordsRef = useRef() as React.MutableRefObject<HTMLSpanElement>;

  const HeadingSpanStyle = {
    display: 'block',
  } as React.CSSProperties;

  useEffect(() => {
    const HeadingAnimationPosition = window.innerWidth < 768 ? 300 : 200;
    gsap.fromTo(
      wordsRef.current,
      { x: -200, y: 200, opacity: 0 },
      {
        duration: 1.5,
        x: 0,
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: wordsRef.current,
          start: `top center+=${HeadingAnimationPosition}`,
        },
      }
    );
  }, []);

  return (
    <span ref={wordsRef} aria-hidden style={HeadingSpanStyle}>
      {children}
    </span>
  );
};
