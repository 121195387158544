import React, { FC } from 'react';
import Img from 'gatsby-image';
import { JewelleryContentQuery } from 'Queries/JewelleryContent';
import {
  SlideInLeftHeading,
  SlideInRightHeading,
  SlideZoomInOut,
} from 'components';

const JewelleryContent: FC = () => {
  const data = JewelleryContentQuery();
  return (
    <div>
      <div className="jewellery">
        {data.jewellery.jewelleryCollections.map(
          (content: any, index: number) => {
            return (
              <div className="jewellery_wrapper" key={`${content.title}`}>
                <div className="jewellery_content">
                  <h2 className="jewellery_title">
                    {index % 2 === 0 ? (
                      <SlideInLeftHeading>{content.title}</SlideInLeftHeading>
                    ) : (
                      <SlideInRightHeading>{content.title}</SlideInRightHeading>
                    )}
                  </h2>
                  <div className="jewellery_text">{content.description}</div>
                </div>
                <div className="jewellery_image">
                  {content.image?.childImageSharp?.fluid && (
                    <SlideZoomInOut>
                      <Img fluid={content.image.childImageSharp.fluid} />
                    </SlideZoomInOut>
                  )}
                  <div className="jewellery_text">{content.imageSummary}</div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export { JewelleryContent };
