import React, { FC } from 'react';

interface QuotatioPropType {
  quotation: string;
}
const Quotation: FC<QuotatioPropType> = ({ quotation }) => {
  if (!quotation) {
    return null;
  }
  return (
    <div className="quotation-section">
      <p className="quotation">{quotation}</p>
    </div>
  );
};

export { Quotation };
