import React, { FC, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface SlideInRightTitleProps {
  title: string;
  classname: string;
}

export const SlideInRightTitle: FC<SlideInRightTitleProps> = ({
  title,
  classname,
}) => {
  const SlideInRightTitleRef = useRef() as React.MutableRefObject<HTMLHeadingElement>;
  React.useEffect(() => {
    const TitleAnimationPosition = window.innerWidth < 768 ? 0 : 20;
    gsap.fromTo(
      SlideInRightTitleRef.current,
      {
        xPercent: TitleAnimationPosition,
      },
      {
        xPercent: 0,
        scrollTrigger: {
          trigger: SlideInRightTitleRef.current,
          scrub: true,
          start: 'top bottom-=200',
          onToggle() {
            ScrollTrigger.refresh();
          },
        },
      }
    );
  }, []);

  return (
    <h3 ref={SlideInRightTitleRef} className={classname}>
      {title}
    </h3>
  );
};
