import {
  EMAIL_INVALID_RESET,
  EMAIL_REQUIRED_RESET,
  EMAIL_INVALID,
  EMAIL_REQUIRED,
  NAME_REQUIRED_RESET,
  NAME_REQUIRED,
  NAME_INVALID_RESET,
  NAME_INVALID,
  CONTACT_US_SUCCESS,
  MESSAGE_REQUIRED,
  MESSAGE_REQUIRED_RESET,
  MESSAGE_INVALID,
  MESSAGE_INVALID_RESET,
  PHONE_NUMBER_REQUIRED,
  PHONE_NUMBER_INVALID_RESET,
  PHONE_NUMBER_INVALID,
  PHONE_NUMBER_REQUIRED_RESET,
  HEARABOUTUS_REQUIRED,
  HEARABOUTUS_REQUIRED_RESET,
  CONTACT_US_FAILURE,
  VALIDATION_RESET,
} from 'Redux/actionTypes';

type InitialStateType = {
  isSubmitSuccess: boolean;
  isNameRequired: boolean;
  isNameInvalid: boolean;
  isEmailRequired: boolean;
  isEmailInvalid: boolean;
  isPhoneNumberRequired: boolean;
  isPhoneNumberInvalid: boolean;
  isMessageRequired: boolean;
  isMessageInvalid: boolean;
  isheardAboutUsRequired: boolean;
  isSubmitFailure: boolean;
};

const InitialState: InitialStateType = {
  isSubmitSuccess: false,
  isNameRequired: false,
  isEmailRequired: false,
  isEmailInvalid: false,
  isPhoneNumberRequired: false,
  isPhoneNumberInvalid: false,
  isMessageRequired: false,
  isMessageInvalid: false,
  isNameInvalid: false,
  isheardAboutUsRequired: false,
  isSubmitFailure: false,
};

const ContactUsReducer: any = (state = InitialState, action: any) => {
  switch (action.type) {
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        isSubmitSuccess: true,
        isNameRequired: false,
        isNameInvalid: false,
        isEmailRequired: false,
        isEmailInvalid: false,
        isPhoneNumberRequired: false,
        isPhoneNumberInvalid: false,
        isMessageRequired: false,
        isMessageInvalid: false,
        isheardAboutUsRequired: false,
        isSubmitFailure: false,
      };
    case NAME_REQUIRED:
      return { ...state, isNameRequired: true };
    case NAME_REQUIRED_RESET:
      return { ...state, isNameRequired: false };
    case NAME_INVALID:
      return { ...state, isNameInvalid: true };
    case NAME_INVALID_RESET:
      return { ...state, isNameInvalid: false };
    case EMAIL_REQUIRED:
      return { ...state, isEmailRequired: true };
    case EMAIL_REQUIRED_RESET:
      return { ...state, isEmailRequired: false };
    case EMAIL_INVALID:
      return { ...state, isEmailInvalid: true };
    case EMAIL_INVALID_RESET:
      return { ...state, isEmailInvalid: false };
    case PHONE_NUMBER_REQUIRED:
      return { ...state, isPhoneNumberRequired: true };
    case PHONE_NUMBER_REQUIRED_RESET:
      return { ...state, isPhoneNumberRequired: false };
    case PHONE_NUMBER_INVALID:
      return { ...state, isPhoneNumberInvalid: true };
    case PHONE_NUMBER_INVALID_RESET:
      return { ...state, isPhoneNumberInvalid: false };
    case MESSAGE_REQUIRED:
      return { ...state, isMessageRequired: true };
    case MESSAGE_REQUIRED_RESET:
      return { ...state, isMessageRequired: false };
    case MESSAGE_INVALID:
      return { ...state, isMessageInvalid: true };
    case MESSAGE_INVALID_RESET:
      return { ...state, isMessageInvalid: false };
    case HEARABOUTUS_REQUIRED:
      return { ...state, isheardAboutUsRequired: true };
    case HEARABOUTUS_REQUIRED_RESET:
      return { ...state, isheardAboutUsRequired: false };
    case CONTACT_US_FAILURE:
      return {
        ...state,
        isSubmitFailure: true,
        isSubmitSuccess: false,
        isNameRequired: false,
        isNameInvalid: false,
        isEmailRequired: false,
        isEmailInvalid: false,
        isPhoneNumberRequired: false,
        isPhoneNumberInvalid: false,
        isMessageRequired: false,
        isMessageInvalid: false,
        isheardAboutUsRequired: false,
      };
    case VALIDATION_RESET:
      return InitialState;
    default:
      return state;
  }
};
export { ContactUsReducer };
