import React, { FC, useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import ReactMarkdown from 'react-markdown';
import CloseIcon from 'assets/svg/close.svg';

interface ModalPropType {
  modalSummary: any;
  modalTitle: string;
  timer?: number;
  isModalVisible: boolean;
}
const Modal: FC<ModalPropType> = ({
  modalTitle,
  modalSummary,
  isModalVisible,
  timer = 5000,
}) => {
  if (!isModalVisible) {
    return null;
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const storagevalue = window.sessionStorage.getItem('ShowNotification');
    if (!storagevalue) {
      setTimeout(() => {
        setIsModalOpen(true);
      }, timer);
    } else {
      setIsModalOpen(false);
    }
  }, []);

  return (
    <ReactModal
      closeTimeoutMS={300}
      isOpen={isModalOpen}
      onRequestClose={() => {
        setIsModalOpen(false);
      }}
      contentLabel={modalTitle}
      ariaHideApp={false}
      portalClassName="ReactModalPortal site-notfication"
    >
      <div className="modal">
        <button
          className="modal-btn"
          type="button"
          onClick={() => {
            sessionStorage.setItem('ShowNotification', 'false');
            setIsModalOpen(false);
          }}
        >
          <CloseIcon />
        </button>
        <h2 className="modal-heading">{modalTitle}</h2>
        <ReactMarkdown className="modal-summary">{modalSummary}</ReactMarkdown>
      </div>
    </ReactModal>
  );
};
export { Modal };
