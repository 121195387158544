import { useStaticQuery, graphql } from 'gatsby';

const MenuQuery = () => {
  return useStaticQuery(graphql`
    query MenuQuery {
      menu: allStrapiGlobal {
        edges {
          node {
            mainNav {
              title
              slug
              id
            }
          }
        }
      }
    }
  `);
};
export { MenuQuery };
