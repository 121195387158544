import React, { FC } from 'react';
import { AccordionSlideDown } from 'components';

interface accordionProp {
  accordion: { title: string; description: string; id: number };
}
const OurPeopleAccordion: FC<accordionProp> = ({ accordion }) => {
  return (
    <AccordionSlideDown
      title={accordion.title}
      description={accordion.description}
      classname="people-list-item-acccordion"
    />
  );
};

export { OurPeopleAccordion };
