import React, { FC, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import ReactMarkdown from 'react-markdown';

gsap.registerPlugin(ScrollTrigger);

interface AccordionSlideDownProps {
  title: string;
  description: any;
  classname: string;
}

const AccordionTitleStyle = {
  height: 0,
  overflow: 'hidden',
  transition: 'height 0.4s',
} as React.CSSProperties;

export const AccordionSlideDown: FC<AccordionSlideDownProps> = ({
  title,
  description,
  classname,
}) => {
  const [Active, inActive] = useState(false);
  const activeClass = Active ? 'active' : '';
  const [Visible, inVisible] = useState(false);
  const visibleClass = Visible ? 'Visible' : '';
  const AccordionTitleRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const AccordionDescriptionRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const AccordionDescriptionWrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [AccordionTimeline] = useState(gsap.timeline({ paused: true }));
  React.useEffect(() => {
    AccordionTimeline.to(AccordionDescriptionRef.current, {
      height: AccordionDescriptionWrapperRef.current.offsetHeight,
    });
    AccordionTitleRef.current.addEventListener('mouseenter', () => {
      inActive(true);
      inVisible(true);
      AccordionTimeline.play();
    });
    AccordionTitleRef.current.addEventListener('mouseleave', () => {
      inActive(false);
      inVisible(false);
      AccordionTimeline.reverse();
    });
  }, [AccordionTitleRef.current]);

  return (
    <div className={`${`${classname}`} ${activeClass}`} ref={AccordionTitleRef}>
      <button type="button" className={`${classname}-title`}>
        {title}
      </button>
      <div
        className={`${classname}-description ${visibleClass}`}
        ref={AccordionDescriptionRef}
        style={AccordionTitleStyle}
      >
        <div ref={AccordionDescriptionWrapperRef}>
          <ReactMarkdown>{description}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};
