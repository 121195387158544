import { graphql, useStaticQuery } from 'gatsby';

const HomePageSliderQuery = () => {
  return useStaticQuery(graphql`
    query HomePageSliderQuery {
      HomePageSlider: strapiOurProcess {
        ourProcessSlide {
          id
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
};
export { HomePageSliderQuery };
