import React from 'react';

const DivWrapperStyle = {
  display: 'inline-block',
  overflow: 'hidden',
  paddingRight: '.2em',
} as React.CSSProperties;

export const DivWrapper = ({ children }: any) => {
  return <div style={DivWrapperStyle}>{children}</div>;
};
