import React, { FC } from 'react';
import { ContactMap, SlideInUpHeading } from 'components';

interface ContactLocationType {
  site: {
    siteMetadata: {
      googleMapAPI: string;
    };
  };
  contact: {
    contactLocation: {
      title: string;
      address: string;
      callTitle?: string;
      appointmentTitle?: string;
      latitudeValue: string;
      longitudeValue: string;
      contactNumber?: string;
    };
  };
}
const ContactLocation: FC<ContactLocationType> = ({ contact, site }) => {
  const {
    title,
    address,
    callTitle,
    appointmentTitle,
    latitudeValue,
    longitudeValue,
    contactNumber,
  } = contact.contactLocation;
  return (
    <div className="contact-page-wrapper">
      <div className="contact-page-location">
        <h2 className="contact-page-heading">
          <SlideInUpHeading delaySec={1.5}>{title}</SlideInUpHeading>
        </h2>
        <div className="contact-page-address">
          <p>{address}</p>
        </div>
        <ul className="contact-page-call">
          <li className="contact-page-call-title">
            <a href={`tel:${contactNumber}`}>{callTitle}</a>
          </li>

          <li className="contact-page-call-title">
            <a href={`tel:${contactNumber}`}>{appointmentTitle}</a>
          </li>
        </ul>
      </div>
      <div className="contact-page-map">
        <ContactMap
          site={site}
          contactMap={contact}
          showAddress
          lat={parseFloat(latitudeValue)}
          lng={parseFloat(longitudeValue)}
        />
      </div>
    </div>
  );
};

export { ContactLocation };
