import React from 'react';

const Linkedin = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22">
      <g fill="none" fillRule="evenodd">
        <g fill="#CC884D">
          <g>
            <path
              d="M20.483 20.48h-4.076v-.236c0-2.305.004-4.61-.004-6.915 0-.336-.025-.677-.089-1.006-.176-.92-.684-1.41-1.652-1.508-.533-.055-1.086-.033-1.616.047-.748.112-1.14.615-1.231 1.453-.031.282-.048.568-.049.852-.004 2.335-.002 4.67-.002 7.005v.31H7.586V6.829h3.988V9c.293-.253.548-.478.807-.698 1.018-.862 2.194-1.324 3.531-1.32 1.112.004 2.19.177 3.12.852.87.633 1.276 1.524 1.404 2.565.01.087.03.173.047.26v9.82zM0 20.483L4.552 20.483 4.552 6.828 0 6.828zM2.283 0c1.257.001 2.27 1.016 2.269 2.274-.001 1.257-1.01 2.27-2.27 2.278C1.03 4.56-.005 3.52 0 2.262.006 1.007 1.023 0 2.283 0"
              transform="translate(-1173 -4413) translate(0 4253) translate(1100 160) translate(73.586 .759)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export { Linkedin };
