/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/require-default-props */
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

interface SeoProps {
  description?: string;
  lang?: string;
  title: string | null;
  image?: string | null;
}

const Seo: FC<SeoProps> = ({ description, lang = 'en', title, image }) => {
  const [location, setlocation] = useState('');
  const [origin, setorigin] = useState('');
  useEffect(() => {
    setlocation(window.location.href);
    setorigin(window.location.origin);
  }, []);

  return (
    <StaticQuery
      query={seoDetailsQuery}
      render={data => {
        const {
          site: {
            siteMetadata: { defaultTitle, isDevEnvironment },
          },
          strapiGlobal: {
            defaultSeo: {
              metaTitle: defaultMetaTitle,
              metaDescription: defaultMetaDescription,
              shareImage: { publicURL: shareImageURL },
            },
          },
        } = data;

        const isDev = isDevEnvironment;

        const seoTitle = title || defaultMetaTitle;
        const seoDescription = description || defaultMetaDescription;
        const metaImage =
          (image && `${origin}${image}`) ??
          (shareImageURL && `${origin}${shareImageURL}`);
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={seoTitle}
            titleTemplate={`%s | ${defaultTitle}`}
            meta={[
              {
                name: `description`,
                content: seoDescription,
              },
              {
                property: `og:title`,
                content: seoTitle,
              },
              {
                property: `og:description`,
                content: seoDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                name: 'og:image:width',
                content: 200,
              },
              {
                name: 'og:image:height',
                content: 150,
              },
              {
                property: `og:url`,
                content: location,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:title`,
                content: seoTitle,
              },
              {
                name: `twitter:description`,
                content: seoDescription,
              },
            ].concat(
              isDev
                ? [
                    {
                      property: `robots`,
                      content: `noindex`,
                    },
                  ]
                : []
            )}
          />
        );
      }}
    />
  );
};

export { Seo };

const seoDetailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        isDevEnvironment
      }
    }
    strapiGlobal {
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`;
