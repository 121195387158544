/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable default-case */
import React, { FC, useEffect, useState } from 'react';
import Img from 'gatsby-image';
import { MoveUpSlide } from 'components';

interface InspirationGalleryProps {
  galleryItems: {
    node: {
      strapiId: number;
      title: string;
      image: {
        childImageSharp: {
          fluid: {
            base64: string;
            aspectRatio: number;
            src: string;
            srcSet: string;
            sizes: string;
          };
        };
      };
    };
  }[];
  handleModalOpen: any;
  imagesToDisplay: any;
}

export const InspirationGallery: FC<InspirationGalleryProps> = ({
  galleryItems,
  handleModalOpen,
  imagesToDisplay,
}) => {
  const [GalleryColumns, setsGalleryColumns]: any[] = useState([]);
  useEffect(() => {
    const galleryColumnCount = window.innerWidth < 576 ? 1 : 3;
    const gallaryColumnArray: Array<any> =
      galleryColumnCount === 3 ? [[], [], []] : [[]];

    galleryItems.slice(0, imagesToDisplay).forEach((element: any, i) => {
      switch (i % galleryColumnCount) {
        case 0:
          gallaryColumnArray[0].push(element);
          break;
        case 1:
          gallaryColumnArray[1].push(element);
          break;
        case 2:
          gallaryColumnArray[2].push(element);
          break;
      }
    });
    setsGalleryColumns(gallaryColumnArray);
  }, [galleryItems, imagesToDisplay]);
  return (
    <>
      {galleryItems.length === 0
        ? 'No Result Found'
        : GalleryColumns.map((GalleryColumn: any, i: number) => {
            return (
              <MoveUpSlide
                className="inspiration-section-gallery-column slide-up-down"
                GalleyItemsLength={
                  galleryItems.slice(0, imagesToDisplay).length
                }
                paddingSpacing={i % 2 !== 0 && i !== 0 ? 17 : 22}
                key={`${GalleryColumn}${Math.random()}`}
              >
                {GalleryColumn.map((GalleryItem: any) => {
                  const { title, image } = GalleryItem.node;
                  return (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                      className="inspiration-section-gallery-item"
                      onClick={() => {
                        handleModalOpen(GalleryItem);
                      }}
                      key={`${title}${Math.random()}`}
                    >
                      <Img fluid={image.childImageSharp.fluid} alt={title} />
                      <div className="inspiration-section-gallery-item-content">
                        <p>{title}</p>
                      </div>
                    </div>
                  );
                })}
              </MoveUpSlide>
            );
          })}
    </>
  );
};
