/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { capitalize } from 'utils';

interface FilterProps {
  Filter: {
    title: string;
    options: string[];
  };
  setPageFilter: any;
}

const FilterOptionsStyle = {
  height: 0,
  overflow: 'hidden',
  transition: 'height 0.8s, overflow 0s',
} as React.CSSProperties;

const InspirationFilter: FC<FilterProps> = ({ Filter, setPageFilter }) => {
  const { title, options } = Filter;
  const [FilterValue, setFilterValue]: any = useState([]);
  const [ShowOption, setShowOption]: any = useState(false);
  const FilterListItem = useRef() as React.MutableRefObject<HTMLLIElement>;
  const FilterListItemOptions = useRef() as React.MutableRefObject<HTMLDivElement>;
  const FilterListItemOptionsWrapper = useRef() as React.MutableRefObject<HTMLUListElement>;

  const handleFilterChange = (option: any) => {
    if (!FilterValue.includes(option)) {
      setFilterValue((prevState: any) => [...prevState, option]);
    } else {
      setFilterValue(FilterValue.filter((item: any) => item !== option));
    }
  };
  useEffect(() => {
    if (FilterListItem.current.classList.contains('show')) {
      const ListItemOptionsHeight =
        FilterListItemOptionsWrapper.current.clientHeight;
      FilterListItemOptions.current.style.height = `${ListItemOptionsHeight}px`;
    } else {
      FilterListItemOptions.current.style.height = `0px`;
    }
  }, [ShowOption]);
  useEffect(() => {
    setPageFilter((prevState: any) => ({
      ...prevState,
      [title]: FilterValue,
    }));
  }, [FilterValue]);
  return (
    <li
      className={classNames('inspiration-section-filter-list-item', {
        show: ShowOption,
      })}
      onClick={() => setShowOption(!ShowOption)}
      ref={FilterListItem}
    >
      {capitalize(title)}
      <div
        className="inspiration-section-filter-list-item-options"
        style={FilterOptionsStyle}
        ref={FilterListItemOptions}
      >
        <ul
          className="inspiration-section-filter-list-item-options-wrapper"
          ref={FilterListItemOptionsWrapper}
        >
          {options.map((option: any) => {
            return (
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              <InspirationFilterButton
                key={option}
                option={option}
                handleFilterChange={handleFilterChange}
              />
            );
          })}
        </ul>
      </div>
    </li>
  );
};

export const InspirationFilterButton = ({
  option,
  handleFilterChange,
}: any) => {
  const [ActiveFilter, setActiveFilter]: any = useState(false);
  return (
    <li
      className={classNames('inspiration-section-filter-list-item-option', {
        active: ActiveFilter,
      })}
    >
      <button
        type="button"
        onClick={e => {
          handleFilterChange(option);
          setActiveFilter(!ActiveFilter);
          e.stopPropagation();
        }}
      >
        {capitalize(option)}
        {ActiveFilter && <span>&#x2715;</span>}
      </button>
    </li>
  );
};

export { InspirationFilter };
