import React from 'react';

const Instagram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#CC884D">
          <g>
            <path
              d="M10.91 19.915v.015c.776 0 1.552.003 2.329 0 .776-.005 1.553.005 2.328-.028 2.564-.106 4.03-1.272 4.18-3.806.193-3.283.232-6.591.056-9.874-.148-2.768-1.383-3.914-4.108-4.02-3.241-.125-6.493-.076-9.738.009-2.196.057-3.65 1.311-3.769 3.484-.181 3.357-.26 6.738-.07 10.09.166 2.948 1.657 4.09 4.642 4.127 1.383.018 2.766.003 4.15.003m-5.79 1.897C2.396 21.496.262 19.373.137 16.64-.032 12.946-.054 9.232.122 5.54.272 2.398 2.758.218 5.915.125 8.749.04 11.586-.038 14.419.02c1.288.026 2.642.194 3.839.64 2.555.951 3.588 3.104 3.67 5.676.098 3.097.092 6.202.008 9.3-.105 3.894-2.602 6.279-6.499 6.34-1.503.024-3.007.004-4.51.004-.001.032-3.889.055-5.808-.168"
              transform="translate(-1137 -4413) translate(0 4253) translate(1100 160) translate(37.931)"
            />
            <path
              d="M7.38 11.002c.002 2.014 1.6 3.624 3.588 3.614 1.96-.01 3.639-1.67 3.648-3.607.01-2.03-1.611-3.632-3.67-3.627-2.006.005-3.569 1.59-3.566 3.62m3.615-5.692c3.095.002 5.713 2.624 5.695 5.703-.019 3.08-2.647 5.68-5.74 5.677-3.135-.004-5.643-2.548-5.64-5.721.004-3.16 2.516-5.66 5.685-5.659M15.931 4.905c.005-.584.528-1.111 1.103-1.112.608 0 1.168.54 1.173 1.131.005.572-.575 1.14-1.169 1.145-.62.005-1.112-.513-1.107-1.164"
              transform="translate(-1137 -4413) translate(0 4253) translate(1100 160) translate(37.931)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export { Instagram };
