import React, { FC } from 'react';
import { HeroBannerQuery } from 'Queries/HeroBanner';
import { BannerTitleAnimation, Banner } from 'components';

const HeroBanner: FC = () => {
  const { HomeBanner } = HeroBannerQuery();
  const banner = HomeBanner.edges[0].node.heroBanner;
  const generateRandomTitle = (maxValue: any) => {
    const randomTitle = Math.floor(Math.random() * maxValue);
    return banner.bannerTitle[randomTitle].title;
  };
  const BannerTitle =
    banner.bannerTitle.length > 1
      ? generateRandomTitle(banner.bannerTitle.length)
      : banner.bannerTitle[0].title;
  return (
    <div className="hero-banner">
      <div className="container">
        <div className="hero-banner-wrapper">
          <h1 className="hero-banner-heading">
            {banner.bannerTitle.length && (
              <BannerTitleAnimation>{BannerTitle}</BannerTitleAnimation>
            )}
          </h1>
          <div className="hero-banner-slider">
            <Banner PageBanner={banner} BannerLength={banner.banner.length} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { HeroBanner };
