import React, { FC, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import RightArrow from 'assets/svg/cta-arrow.svg';

gsap.registerPlugin(ScrollTrigger);

interface CTALinkProps {
  to: string;
  children: React.ReactElement | string;
  widthFrom?: string | number;
  widthTo?: string | number;
  duration?: number;
  maxWidth?: string | number;
}

export const CTALink: FC<CTALinkProps> = ({
  to,
  children,
  widthFrom = '15%',
  widthTo = '100%',
  duration = 1,
  maxWidth = '500px',
}) => {
  const arrowRef = useRef(null);

  useEffect(() => {
    const element = arrowRef.current;
    if (element) {
      gsap.fromTo(
        arrowRef.current,
        { width: widthFrom },
        {
          duration,
          width: widthTo,
          scrollTrigger: {
            trigger: element,
            start: 'top center+=200',
            toggleActions: 'play none none reverse',
          },
        }
      );
    }
  }, []);

  return (
    <Link to={`/${to}`} className="cta-link" style={{ maxWidth }}>
      <span className="cta-link_text">{children}</span>
      <span className="cta-link_arrow-wrapper">
        <span className="cta-link_arrow" ref={arrowRef}>
          <RightArrow />
        </span>
      </span>
    </Link>
  );
};
