import React, { FC } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from 'assets/images/marker.png';

interface MapProps {
  showAddress: boolean;
  lat: number;
  lng: number;
  site: {
    siteMetadata: {
      googleMapAPI: string;
    };
  };
  contactMap: {
    contactLocation: {
      latitudeValue: string;
      longitudeValue: string;
      address: string;
    };
  };
}
const InfoWindow: FC<MapProps> = props => {
  const { showAddress, contactMap } = props;
  return showAddress ? (
    <div className="info-window" style={{ width: 150, height: 100 }}>
      {contactMap.contactLocation.address}
    </div>
  ) : null;
};

const ContactusMap = () => {
  return {
    styles: [
      {
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      { elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      { elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#c9b2a6' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#dcd2be' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#ae9e90' }],
      },
      {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [
          { color: '#dfd2ae' },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#93817c' }],
      },
      {
        featureType: 'poi.attraction',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#447530' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#f5f1e6' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ color: '#fdfcf8' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#f8c967' }],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#806b63' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          { color: '#dfd2ae' },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.fill',
        stylers: [
          { color: '#8f7d77' },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.stroke',
        stylers: [
          { color: '#ebe3cd' },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#b9d3c2' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#92998d' }],
      },
    ],
  };
};

const ContactMap: FC<MapProps> = ({ contactMap, site }) => {
  const { latitudeValue, longitudeValue, address } = contactMap.contactLocation;
  const { googleMapAPI } = site.siteMetadata;

  const renderMarkers = (map: any, maps: any) => {
    // eslint-disable-next-line no-new
    new maps.Marker({
      position: {
        lat: parseFloat(latitudeValue),
        lng: parseFloat(longitudeValue),
      },
      map,
      icon: Marker,
      title: address,
    });
  };
  return (
    <div style={{ height: '650px', width: '100%' }}>
      <GoogleMapReact
        defaultCenter={{
          lat: parseFloat(latitudeValue),
          lng: parseFloat(longitudeValue),
        }}
        defaultZoom={14}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        options={ContactusMap}
        yesIWantToUseGoogleMapApiInternals
        resetBoundsOnResize
        bootstrapURLKeys={{
          key: googleMapAPI,
        }}
      >
        <InfoWindow
          lat={parseFloat(latitudeValue)}
          lng={parseFloat(longitudeValue)}
          showAddress
          contactMap={contactMap}
          site={site}
        />
      </GoogleMapReact>
    </div>
  );
};

export { ContactMap };
