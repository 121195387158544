/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

interface FAQProps {
  faqheading: string;
  faq: {
    id: number;
    question: string;
    answer: string;
  }[];
}

const Faq: FC<FAQProps> = ({ faq, faqheading }) => {
  useEffect(() => {
    const faqItem = document.getElementsByClassName('faq');
    const faqTitle = document.getElementsByClassName('faq-title');
    const faqDesc = document.getElementsByClassName('faq-answer-wrapper');
    const removeActiveClass = () => {
      for (let i = 0; i < faqItem.length; i++) {
        faqItem[i].classList.remove('active');
        faqItem[i].children[1].style.height = '0px';
      }
    };
    for (let i = 0; i < faqTitle.length; i++) {
      if (faqDesc) {
        faqItem[i].children[1].style.height = '0px';
      }
      faqTitle[i].addEventListener('click', () => {
        const isFaqActive = !faqItem[i].classList.contains('active');

        removeActiveClass();
        if (isFaqActive) {
          faqItem[i].classList.toggle('active');
          const height =
            faqItem[i].children[1].children[0].getBoundingClientRect().height +
            20;
          faqItem[i].children[1].style.height = `${height}px`;
        }
      });
    }
  }, []);

  return (
    <div className="faq_section">
      <h2 className="faq_heading">{faqheading}&apos;s</h2>
      {faq.map((item: any) => (
        <div className="faq" key={item.id}>
          <button type="button" className="faq-title">
            <p className="faq-title-question">{item.question}</p>
            <div className="toggle_icon" />
          </button>
          <div className="faq-answer-wrapper">
            <ReactMarkdown className="faq-answer">{item.answer}</ReactMarkdown>
          </div>
        </div>
      ))}
    </div>
  );
};

export { Faq };
