import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const SlideZoomInOut: FC = ({ children }) => {
  const wordsRef = useRef([]);
  wordsRef.current = [];

  const addTorefs = (el: any) => {
    if (el && !wordsRef.current.includes(el as never)) {
      wordsRef.current.push(el as never);
    }
  };

  const HeadingSpanStyle = {
    position: 'relative',
    display: 'block',
  } as React.CSSProperties;

  useEffect(() => {
    wordsRef.current.forEach(el => {
      gsap.fromTo(
        el,
        { transform: 'scale(0.5)', webkittransform: 'scale(0.5)' },
        {
          transform: 'scale(1)',
          webkittransform: 'scale(1)',
          scrollTrigger: {
            trigger: el,
            scrub: true,
            start: 'top bottom',
            end: 'bottom top',
          },
        }
      );
    });
  }, []);

  return (
    <span ref={addTorefs} aria-hidden style={HeadingSpanStyle}>
      {children}
    </span>
  );
};
