import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface SlideUpContentProps {
  children: any;
  className?: string;
}

export const SlideUpContent: FC<SlideUpContentProps> = ({
  children,
  className,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const element: any = ref.current;
    if (element != null) {
      gsap.fromTo(
        ref.current,
        { y: 100, opacity: 0 },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: element,
            start: 'top center+=50',
            toggleActions: 'play none none reverse',
          },
        }
      );
    }
  }, [ref.current]);

  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
