export const TOOGLE_NAV = 'TOOGLE_NAV';
export const BOOK_APPOINTMENT_INIT = 'BOOK_APPOINTMENT_INIT';
export const BOOK_APPOINTMENT_SUBMITTED = 'BOOK_APPOINTMENT_SUBMITTED';
export const BOOK_APPOINTMENT_SUCESS = 'BOOK_APPOINTMENT_SUCESS';
export const BOOK_APPOINTMENT_FAILURE = 'BOOK_APPOINTMENT_FAILURE';
export const CONTACT_US_INIT = 'CONTACT_US_INIT';
export const CONTACT_US_SUBMITTED = 'CONTACT_US_SUBMITTED';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCESS';
export const CONTACT_US_FAILURE = 'CONTACT_US_FAILURE';
export const NAME_REQUIRED = 'NAME_REQUIRED';
export const EMAIL_REQUIRED = 'EMAIL_REQUIRED';
export const EMAIL_INVALID = 'EMAIL_INVALID';
export const NAME_REQUIRED_RESET = 'NAME_REQUIRED_RESET';
export const EMAIL_REQUIRED_RESET = 'EMAIL_REQUIRED_RESET';
export const EMAIL_INVALID_RESET = 'EMAIL_INVALID_RESET';
export const NAME_REQUIRED_APP = 'NAME_REQUIRED_APP';
export const EMAIL_REQUIRED_APP = 'EMAIL_REQUIRED_APP';
export const EMAIL_INVALID_APP = 'EMAIL_INVALID_APP';
export const NAME_REQUIRED_RESET_APP = 'NAME_REQUIRED_RESET_APP';
export const PHONE_NUMBER_REQUIRED_APP = 'PHONE_NUMBER_REQUIRED_APP';
export const PHONE_NUMBER_REQUIRED_RESET_APP =
  'PHONE_NUMBER_REQUIRED_RESET_APP';
export const PHONE_NUMBER_INVALID_APP = 'PHONE_NUMBER_INVALID_APP';
export const PHONE_NUMBER_INVALID_RESET_APP = 'PHONE_NUMBER_INVALID_RESET_APP';
export const EMAIL_REQUIRED_RESET_APP = 'EMAIL_REQUIRED_RESET_APP';
export const EMAIL_INVALID_RESET_APP = 'EMAIL_INVALID_RESET_APP';
export const MESSAGE_REQUIRED = 'MESSAGE_REQUIRED';
export const MESSAGE_REQUIRED_RESET = 'MESSAGE_REQUIRED_RESET';
export const MESSAGE_INVALID = 'MESSAGE_INVALID';
export const MESSAGE_INVALID_RESET = 'MESSAGE_INVALID_RESET';
export const NAME_INVALID = 'NAME_INVALID';
export const NAME_INVALID_RESET = 'NAME_INVALID_RESET';
export const NAME_INVALID_APP = 'NAME_INVALID_APP';
export const NAME_INVALID_RESET_APP = 'NAME_INVALID_RESET_APP';
export const PHONE_NUMBER_REQUIRED = 'PHONE_NUMBER_REQUIRED';
export const PHONE_NUMBER_INVALID_RESET = 'PHONE_NUMBER_INVALID_RESET';
export const PHONE_NUMBER_INVALID = 'PHONE_NUMBER_INVALID';
export const PHONE_NUMBER_REQUIRED_RESET = 'PHONE_NUMBER_REQUIRED_RESET';
export const VALIDATION_RESET = 'VALIDATION_RESET';
export const HEARABOUTUS_REQUIRED = 'HEARABOUTUS_REQUIRED';
export const HEARABOUTUS_REQUIRED_RESET = 'HEARABOUTUS_REQUIRED_RESET';
export const MESSAGE_REQUIRED_APP = 'MESSAGE_REQUIRED_APP';
export const MESSAGE_INVALID_RESET_APP = 'MESSAGE_INVALID_RESET_APP';
export const MESSAGE_REQUIRED_RESET_APP = 'MESSAGE_REQUIRED_RESET_APP';
export const MESSAGE_INVALID_APP = 'MESSAGE_INVALID_APP';
export const HEARABOUTUS_REQUIRED_APP = 'HEARABOUTUS_REQUIRED_APP';
export const HEARABOUTUS_REQUIRED_RESET_APP = 'HEARABOUTUS_REQUIRED_RESET_APP';
