import React, { FC, useEffect, useState } from 'react';
import 'sass/style.scss';
import { Seo, Quotation, BookAppointment, Loader, Modal } from 'components';
import { LayoutQuery } from 'Queries/Layout';

export interface PageLayoutProps {
  title: string | null;
  children: JSX.IntrinsicElements['div'];
  quotation?: string;
  showBookAppointmentForm?: boolean;
  icon?: JSX.IntrinsicElements['svg'] | string;
  modalSummary?: any;
  modalTitle?: string;
  className?: string;
  metaTitle: string;
  description?: string;
  image?: string;
}

const PageLayout: FC<PageLayoutProps> = ({
  title,
  metaTitle,
  description,
  image,
  children,
  quotation = '',
  showBookAppointmentForm = true,
  icon = '',
  className = '',
}: PageLayoutProps) => {
  const data = LayoutQuery();
  const {
    strapiGlobal: {
      sitePopup: {
        visible: isModalVisible,
        title: modalTitle,
        summary: modalSummary,
      },
    },
    site: {
      siteMetadata: { bookAppointmentURL },
    },
    bookappointmentform,
  } = data;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  return (
    <>
      <Seo title={metaTitle ?? title} description={description} image={image} />
      <Modal
        isModalVisible={isModalVisible}
        modalSummary={modalSummary}
        modalTitle={modalTitle}
      />
      <div className={className}>
        {children}
        {quotation && <Quotation quotation={quotation} />}
        <div className="appointment-section">
          {showBookAppointmentForm && (
            <div className="appointment">
              <BookAppointment
                bookAppointmentURL={bookAppointmentURL}
                bookappointmentform={bookappointmentform}
              />
            </div>
          )}
          {icon && <div className="icon">{icon}</div>}
        </div>
      </div>
      <Loader loading={loading} />
    </>
  );
};
export { PageLayout };
