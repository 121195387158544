/* eslint-disable radix */
import React, { FC } from 'react';
import { SlideInUpLeft, SlideInUpRight } from 'components';

interface OurProcessPageSlideType {
  slides: {
    id: string;
    title: string;
    className: string;
    description: string;
    image: {
      childImageSharp: {
        fluid: {
          base64: string;
          aspectRatio: number;
          src: string;
          srcSet: string;
          sizes: string;
        };
      };
    };
  }[];
}
const ProcessPageSlider: FC<OurProcessPageSlideType> = ({ slides }) => {
  const sortedSlides = slides.sort((a: any, b: any) => {
    return a.id - b.id;
  });
  return (
    <div className="process-page-slider">
      {sortedSlides.map((slide: any, i: number) => {
        const { title, className, image, description, id } = slide;
        const slideCount = `0${i + 1}`.slice(-2);
        if (i % 2 === 0 && i !== 0) {
          return (
            <SlideInUpRight
              title={title}
              slideCount={slideCount}
              dynamicClass={className}
              classname="process-page-slide"
              image={image}
              description={description}
              id={id}
              key={id}
            />
          );
        }
        return (
          <SlideInUpLeft
            title={title}
            slideCount={slideCount}
            dynamicClass={className}
            classname="process-page-slide"
            image={image}
            description={description}
            id={id}
            key={id}
          />
        );
      })}
    </div>
  );
};

export { ProcessPageSlider };
