import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface RotateIconProps {
  icon: React.ReactNode;
  classname: string;
  transformOrigin?: string;
}

export const RotateIcon: FC<RotateIconProps> = ({
  icon,
  classname,
  transformOrigin,
}) => {
  const RotateIconref = useRef() as React.MutableRefObject<HTMLHeadingElement>;

  useEffect(() => {
    const RotateIconTween = gsap.fromTo(
      RotateIconref.current,
      { transformOrigin: transformOrigin ?? transformOrigin },
      {
        rotate: 360,
        transformOrigin: transformOrigin ?? transformOrigin,
        duration: 3,
        paused: true,
        scrollTrigger: {
          trigger: RotateIconref.current,
          start: 'top bottom=200',
          toggleActions: 'play none none reverse',
          scrub: 1,
        },
      }
    );
    RotateIconref.current.addEventListener('mouseenter', () => {
      RotateIconTween.restart();
    });
    RotateIconref.current.addEventListener('mouseleave', () => {
      RotateIconTween.reverse();
    });
  }, [RotateIconref.current]);

  return (
    <>
      <div className={classname} ref={RotateIconref}>
        {icon}
      </div>
    </>
  );
};
