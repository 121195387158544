import React, { FC, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Link } from 'gatsby';
import { Instagram } from 'assets/tsx/instagram';
import { Youtube } from 'assets/tsx/youtube';
import { Linkedin } from 'assets/tsx/linkedin';
import { Facebook } from 'assets/tsx/facebook-icon';
import PhoneIcon from 'assets/svg/phone.svg';
import LF from 'assets/svg/lf.svg';
import { MailIcon } from 'assets/tsx/mail-icon';

gsap.registerPlugin(ScrollTrigger);

interface SlidingUPElementProps {
  address: string;
  youtubeLink: string;
  instagramLink: string;
  linkedinLink: string;
  facebookLink: string;
  socialMediaTitle: string;
  mailLink: string;
  landLineNumber: string;
  menu: {
    node: {
      mainNav: {
        title: string;
        slug: string;
        id: number;
      }[];
    };
  }[];
}

export const SlidingUPElement: FC<SlidingUPElementProps> = ({
  menu,
  address,
  socialMediaTitle,
  facebookLink,
  instagramLink,
  linkedinLink,
  youtubeLink,
  mailLink,
  landLineNumber,
}) => {
  // eslint-disable-next-line no-unused-vars
  function handleIntersection(entries: any) {
    // eslint-disable-next-line array-callback-return
    entries.map((entry: any) => {
      if (entry.isIntersecting) {
        gsap.fromTo(
          '.footer-item li',
          {
            y: 20,
            opacity: 0,
            skewX: 10,
          },
          {
            y: 0,
            skewX: 0,
            opacity: 1,
            duration: 0.5,
            stagger: 0.1,
          }
        );
        gsap.fromTo(
          '.footer-logo',
          {
            y: 100,
            opacity: 0,
            scale: 0.5,
          },
          {
            y: 0,
            opacity: 1,
            scale: 1,
          }
        );
        gsap.fromTo(
          [
            '.footer-social-media-title',
            '.footer-social-media-icon svg',
            '.footer-address',
          ],
          {
            y: 20,
            opacity: 0,
            skewX: 10,
          },
          {
            y: 0,
            skewX: 0,
            opacity: 1,
            duration: 0.5,
            stagger: 0.1,
          }
        );
      }
    });
  }
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    const footerContainer = document.querySelector('.footer-main');
    if (footerContainer) {
      observer.observe(footerContainer);
    }
  }, []);
  const FilteredMenu = menu[0].node.mainNav.filter((item: any) => {
    return item.slug !== '/';
  });
  return (
    <>
      <div className="footer-main">
        <div className="footer-content">
          <div className="footer-logo" data-test="footer-logo">
            <Link to="/">
              <LF />
            </Link>
            <p>
              Built by
              <a href="https://ozzi.tech/"> OzziTech</a>
            </p>
          </div>
          <ul className="footer-item">
            {FilteredMenu &&
              FilteredMenu.map((item: any) => {
                return (
                  <li key={`${item.id}`}>
                    <Link to={item.slug}>{item.title}</Link>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="footer-social-media">
          {socialMediaTitle && (
            <div className="footer-social-media-title">{socialMediaTitle}</div>
          )}
          <div className="footer-social-media-icon">
            <ul>
              {mailLink && (
                <li className="footer-mail-icon">
                  <a href={`mailto:${mailLink}`} rel="noreferrer">
                    <MailIcon />
                  </a>
                </li>
              )}
              {youtubeLink && (
                <li>
                  <a href={youtubeLink} target="_blank" rel="noreferrer">
                    <Youtube />
                  </a>
                </li>
              )}
              {facebookLink && (
                <li>
                  <a href={facebookLink} target="_blank" rel="noreferrer">
                    <Facebook />
                  </a>
                </li>
              )}
              {instagramLink && (
                <li>
                  <a href={instagramLink} target="_blank" rel="noreferrer">
                    <Instagram />
                  </a>
                </li>
              )}
              {linkedinLink && (
                <li>
                  <a href={linkedinLink} target="_blank" rel="noreferrer">
                    <Linkedin />
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="footer-phone-number">
            <a href={`tel:${landLineNumber}`}>
              <PhoneIcon />
              <span>{landLineNumber}</span>
            </a>
          </div>
          <address className="footer-address" data-test="footer-address">
            {address}
          </address>
        </div>
      </div>
    </>
  );
};
