import React, { FC, useRef, useState, useEffect } from 'react';
import VolumeIcon from 'assets/svg/volume-icon.svg';

interface VideoPlayerPropType {
  Src: string;
  ShowVolumeControl?: boolean;
}

const VideoPlayer: FC<VideoPlayerPropType> = ({
  Src,
  ShowVolumeControl = false,
}) => {
  const VideoRef = useRef() as React.MutableRefObject<HTMLVideoElement>;

  const [muted, setMuted] = useState(true);
  useEffect(() => {
    if (VideoRef) {
      VideoRef.current!.muted = muted;
    }
  }, [muted]);

  return (
    <>
      <video preload="auto" loop autoPlay muted playsInline ref={VideoRef}>
        <source src={Src} type="video/mp4" />
      </video>
      {ShowVolumeControl && (
        <div className="video-volume-controls">
          <button
            type="button"
            onClick={() => {
              setMuted(!muted);
            }}
          >
            <VolumeIcon />
            {muted ? 'Activate sound' : 'Deactivate sound'}
          </button>
        </div>
      )}
    </>
  );
};

export { VideoPlayer };
