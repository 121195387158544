import React, { FC } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { HomePageSliderQuery } from 'Queries/HomePageSlider';
import { SliderAnimation } from 'components';

const HomePageSlider: FC = () => {
  const data = HomePageSliderQuery();
  const sortedSlides = data.HomePageSlider.ourProcessSlide.sort(
    (a: any, b: any) => {
      return a.id - b.id;
    }
  );
  return (
    <div className="home-page-slider">
      <SliderAnimation className="home-page-slider-container">
        {sortedSlides.map((slide: any, i: number) => {
          const { title, image, id } = slide;
          const slideCount = `0${i + 1}`.slice(-2);
          return (
            <div className="home-page-slider-item" key={id}>
              <div className="home-page-slider-image">
                <Link to="our-process">
                  {image?.childImageSharp?.fluid && (
                    <Img fluid={image.childImageSharp.fluid} />
                  )}
                </Link>
              </div>
              <div className="home-page-slider-content">
                <p className="home-page-slider-content-count">{slideCount}.</p>
                <p className="home-page-slider-content-title">
                  <span>{title}</span>
                </p>
              </div>
            </div>
          );
        })}
      </SliderAnimation>
    </div>
  );
};

export { HomePageSlider };
