import React, { FC, useEffect, useState } from 'react';
import { submitBookAppointment, resetBookAppointment } from 'Redux/actions';
import { useSelector, useDispatch } from 'react-redux';

interface BookAppointmentProps {
  bookAppointmentURL: string;
  bookappointmentform: {
    contactForm: {
      contactQuery: {
        id: number;
        title: string;
      }[];
      heardAboutUs: {
        id: number;
        value: string;
      }[];
      heardAboutUsTitle: string;
      queryTitle: string;
      buttonText: string;
      title: string;
      formFailureMessage: string;
      formSuccessMessage: string;
    };
  };
}

const BookAppointment: FC<BookAppointmentProps> = ({
  bookAppointmentURL,
  bookappointmentform,
}) => {
  const {
    heardAboutUsTitle,
    queryTitle,
    contactQuery,
    heardAboutUs,
    buttonText,
    title,
    formFailureMessage,
    formSuccessMessage,
  } = bookappointmentform.contactForm;
  const dispatch = useDispatch();
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState('');
  const [phonenumber, changephonenumber] = useState('');
  const [heardAboutus, changeheardAboutus] = useState('');
  const [bookanAppointmentMessage, changebookanAppointmentMessage] =
    useState('');
  const [select, selectedValue] = useState(contactQuery[0].title);
  const {
    isSubmitSucess,
    isSubmitFailure,
    isAppointmentNameInvalid,
    isAppointmentNameRequired,
    isAppointmentEmailRequired,
    isAppointmentEmailInvalid,
    isAppointmentPhoneNumberRequired,
    isAppointmentPhoneNumberInvalid,
    isAppointmentMessageRequired,
    isAppointmentMessageInvalid,
    isAppointmentheardAboutUsRequired,
  } = useSelector((state: any) => {
    return state.BookAppointmentReducer;
  });
  const handleSubmit = (e: any) => {
    if (isSubmitSucess === true) {
      dispatch(resetBookAppointment());
    }
    e.preventDefault();
    dispatch(
      submitBookAppointment({
        name,
        email,
        phonenumber,
        bookAppointmentURL,
        heardAboutus,
        bookanAppointmentMessage,
        select,
      })
    );
  };

  useEffect(() => {
    if (isSubmitSucess === true) {
      changeName('');
      changeEmail('');
      changephonenumber('');
      changebookanAppointmentMessage('');
      changeheardAboutus('');
    }
  }, [isSubmitSucess]);

  useEffect(() => {
    return () => {
      dispatch(resetBookAppointment());
    };
  }, []);

  return (
    <div className="bookappointment">
      <h3 className="contact-page-form-title">{title}</h3>
      <div className="bookappointment-form">
        {isSubmitSucess && (
          <div className="submit-message">{formSuccessMessage}</div>
        )}
        {isSubmitFailure && (
          <div className="submit-message">{formFailureMessage}</div>
        )}
        <form noValidate id="bookappointment" onSubmit={handleSubmit}>
          <div className="contact-page-form-message ">
            <div className="contact-page-form-textarea">
              <textarea
                name="contact_message"
                className="contact-message"
                rows={9}
                cols={20}
                value={bookanAppointmentMessage}
                onChange={e => {
                  changebookanAppointmentMessage(e.target.value);
                }}
              />
              {isAppointmentMessageRequired && (
                <div className="submit-message">* Required</div>
              )}
              {isAppointmentMessageInvalid && (
                <div className="submit-message">Field Cannot be Empty</div>
              )}
            </div>
            <div className="contact-page-form-checkbox">
              <div className="contact-page-form-checkbox-wrapper">
                <div className="form_fields contact-page-heardaboutus-form">
                  <div className="form_fields_text">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={name}
                      onChange={e => {
                        changeName(e.target.value);
                      }}
                    />
                    {isAppointmentNameRequired && (
                      <div className="submit-message">*Required</div>
                    )}
                    {isAppointmentNameInvalid && (
                      <div className="submit-message">
                        Minimum 2 characters or more
                      </div>
                    )}
                  </div>
                  <div className="form_fields_text">
                    <input
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      value={email}
                      onChange={e => {
                        changeEmail(e.target.value);
                      }}
                    />
                    {isAppointmentEmailRequired && (
                      <div className="submit-message">*Required</div>
                    )}
                    {isAppointmentEmailInvalid && (
                      <div className="submit-message">Invalid Email ID</div>
                    )}
                  </div>
                  <div className="form_fields_text">
                    <input
                      type="number"
                      name="Phone Number"
                      placeholder="Phone Number"
                      value={phonenumber}
                      onChange={e => {
                        changephonenumber(e.target.value);
                      }}
                    />
                    {isAppointmentPhoneNumberRequired && (
                      <div className="submit-message">*Required</div>
                    )}
                    {isAppointmentPhoneNumberInvalid && (
                      <div className="submit-message">
                        Invalid Mobile Number. Use 10 digits only
                      </div>
                    )}
                  </div>
                </div>
                <div className="contact-page-heardaboutus-form">
                  <h5 className="contact-page-form-checkbox-title">
                    {heardAboutUsTitle}
                  </h5>
                  <select
                    className="form_select"
                    onChange={e => {
                      changeheardAboutus(e.target.value);
                    }}
                  >
                    <option value="select" disabled selected>
                      Select
                    </option>
                    {heardAboutUs.map((item: any) => {
                      return (
                        <option value={item.value} key={item.id}>
                          {item.value}
                        </option>
                      );
                    })}
                  </select>
                  {isAppointmentheardAboutUsRequired && (
                    <div className="submit-message">
                      *Please select one in the list{' '}
                    </div>
                  )}
                </div>
                <div className="contact-page-form-radio">
                  <h5 className="contact-page-form-checkbox-title">
                    {queryTitle}
                  </h5>
                  {contactQuery.map((content: any) => {
                    return (
                      <div
                        className="contact-page-form-checkbox-radioButton"
                        key={content.id}
                      >
                        <input
                          type="radio"
                          name={content.title}
                          value={content.title}
                          checked={select === content.title}
                          onChange={() => selectedValue(content.title)}
                        />
                        <span
                          role="button"
                          tabIndex={0}
                          className="contact-page-label"
                          onClick={() => selectedValue(content.title)}
                          onKeyDown={() => selectedValue(content.title)}
                        >
                          {content.title}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="contact-page-form-submit">
                  <span className="submit">
                    <button type="submit" className="action">
                      {buttonText}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { BookAppointment };
