import { useStaticQuery, graphql } from 'gatsby';

const HomePageContentQuery = () => {
  return useStaticQuery(graphql`
    query ContentQuery {
      content: strapiHomePage {
        contentSection {
          summaryContent
          summaryTitle
          title
          id
        }
        callToAction {
          link
          title
          id
        }
      }
    }
  `);
};
export { HomePageContentQuery };
