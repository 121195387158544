import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface HighlightSummaryProps {
  children: string;
}

export const HighlightSummaryAnimation: FC<HighlightSummaryProps> = ({
  children,
}) => {
  const summaryref = useRef(null);

  useEffect(() => {
    const element: any = summaryref.current;
    if (element != null) {
      gsap.fromTo(
        summaryref.current,
        { backgroundSize: '0 100%' },
        {
          duration: 3,
          ease: 'power3.easeInOut',
          backgroundSize: '100% 100%',
          scrollTrigger: {
            trigger: element,
            start: 'top center+=50',
          },
        }
      );
    }
  }, [summaryref.current]);

  return (
    <span className="title" ref={summaryref}>
      {children}
    </span>
  );
};
