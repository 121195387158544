import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { splitChars } from 'utils';

gsap.registerPlugin(ScrollTrigger);

interface BannerTitleAnimationProps {
  children: string;
}

export const BannerTitleAnimation: FC<BannerTitleAnimationProps> = ({
  children,
}) => {
  const charsRef = useRef([]);
  charsRef.current = [];

  const addTorefs = (el: any) => {
    if (el && !charsRef.current.includes(el as never)) {
      charsRef.current.push(el as never);
    }
  };
  useEffect(() => {
    const TitleAnimationPosition = window.innerWidth < 768 ? 800 : 1200;
    const el = charsRef.current.map(chars => chars);
    for (let i = 0; i < el.length; i += 1) {
      const timeline = gsap.timeline();
      timeline.fromTo(
        el[i * 2],
        { y: 0 },
        {
          scrollTrigger: {
            trigger: 'body',
            start: 'top',
            scrub: 1,
            end: 'bottom top',
          },
          duration: 1,
          ease: 'power3.easeInOut',
          y: -(Math.random() * 1400),
        }
      );

      timeline.fromTo(
        el[i * 2 - 1],
        { y: 0 },
        {
          scrollTrigger: {
            trigger: 'body',
            start: 'top',
            end: 'bottom top',
            scrub: 1,
          },
          duration: 1,
          ease: 'power2.easeInOut',
          y: Math.random() * TitleAnimationPosition,
        }
      );
    }
  }, []);
  return (
    <>
      {splitChars(children).map((chars, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index} ref={addTorefs} aria-hidden>
          {chars}
        </span>
      ))}
    </>
  );
};
