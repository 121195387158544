import React, { FC } from 'react';
import LF from 'assets/svg/lf.svg';

interface LoaderProps {
  loading: boolean;
}

const Loader: FC<LoaderProps> = ({ loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <div className="page-loader">
      <LF />
    </div>
  );
};

export { Loader };
