import React, { FC, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { toggleNav } from 'Redux/actions';
import { HamburgerMenu } from 'assets/tsx/hamburger';
import LeoLogo from 'assets/svg/leo-fernandez.svg';
import { MailIcon } from 'assets/tsx/mail-icon';
import { Menu } from '../Menu';

interface HeaderProps {
  mailLink?: string;
  landLineNumber?: string;
}

const Header: FC<HeaderProps> = ({ mailLink, landLineNumber }) => {
  const [scroll, setScroll] = useState(false);
  const dispatch = useDispatch();
  const { isNavOpen } = useSelector((state: any) => {
    return state.NavReducer;
  });
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 250);
    });
    if (isNavOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (document.body.style.overflow = 'unset'), 2000);
    }
  }, [isNavOpen]);
  return (
    <header
      className={classNames('header', {
        active: isNavOpen,
        scrolled: scroll,
      })}
    >
      <div className="container">
        <div className="header-container">
          <h1 className="logo" data-test="logo">
            <Link to="/" className="logo_icon">
              <LeoLogo />
            </Link>
          </h1>
          <div className="header-wrapper">
            <ul className="header-wrapper-content">
              <li className="header-wrapper-phone-number">
                <a href={`tel:${landLineNumber}`}>{landLineNumber}</a>
              </li>
              <li className="header-wrapper-svg">
                <a href={`mailto:${mailLink}`}>
                  <MailIcon />
                </a>
              </li>
            </ul>
            <button
              type="button"
              data-test="hamburger-menu"
              className="hamburger-menu"
              onClick={() => {
                dispatch(toggleNav());
              }}
              aria-label="hamburger menu"
            >
              <HamburgerMenu />
            </button>
          </div>
        </div>
      </div>
      <Menu />
    </header>
  );
};

export { Header };
