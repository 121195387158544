import { useStaticQuery, graphql } from 'gatsby';

const JewelleryContentQuery = () => {
  return useStaticQuery(graphql`
    query JewelleryQuery {
      jewellery: strapiJewelleryPage {
        jewelleryCollections {
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          description
          imageSummary
        }
      }
    }
  `);
};
export { JewelleryContentQuery };
