import React, { FC, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { splitText } from 'utils';
import { DivWrapper } from 'components';

gsap.registerPlugin(ScrollTrigger);

interface SlideInUpHeadingProps {
  children: string;
  delaySec?: number;
}

export const SlideInUpHeading: FC<SlideInUpHeadingProps> = ({
  children,
  delaySec = 0,
}) => {
  const wordsRef = useRef([]);
  wordsRef.current = [];

  const addTorefs = (el: any) => {
    if (el && !wordsRef.current.includes(el as never)) {
      wordsRef.current.push(el as never);
    }
  };

  const HeadingSpanStyle = {
    position: 'relative',
    display: 'inline-block',
  } as React.CSSProperties;

  React.useEffect(() => {
    const startAnimation =
      window.innerWidth < 750 ? 'top-=200 bottom-=150' : 'top bottom-=150';
    wordsRef.current.forEach(el => {
      gsap.fromTo(
        el,
        { skewX: 30, y: 100, rotationX: -60 },
        {
          skewX: 0,
          y: 0,
          rotationX: 0,
          duration: 0.5,
          stagger: 0.2,
          delay: delaySec,
          scrollTrigger: {
            trigger: el,
            start: startAnimation,
            toggleActions: 'play none none reverse',
          },
        }
      );
    });
  }, []);

  return (
    <>
      {children &&
        splitText(children).map((word, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <DivWrapper key={`${word}${index}`}>
            <span
              key={`title-${word}`}
              ref={addTorefs}
              aria-hidden
              style={HeadingSpanStyle}
            >
              {word}{' '}
            </span>
          </DivWrapper>
        ))}
    </>
  );
};
