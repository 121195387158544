import React, { FC } from 'react';
import Beetle from 'assets/svg/lfj-beetle.svg';
import { HomePageContentQuery } from 'Queries/HomePageContent';
import {
  SlideInUpHeading,
  SlideUpContent,
  SlideUpParagraph,
  CTALink,
} from 'components';
import ReactMarkdown from 'react-markdown';

const HomePageContent: FC = () => {
  const data = HomePageContentQuery();
  const { title, summaryContent, summaryTitle } = data.content.contentSection;
  return (
    <div className="container">
      <div className="row home_summary">
        <div className="home_summary_wrapper col-xl-5">
          <h2 className="section_large_heading" aria-label={title}>
            <SlideInUpHeading>{title}</SlideInUpHeading>
          </h2>
        </div>
        <div className="home_summary_wrapper col-xl-7">
          <div className="home_summary_content">
            <Beetle />
          </div>
          <h3 className="section_small_heading">
            <SlideUpContent>{summaryTitle}</SlideUpContent>{' '}
          </h3>
          <SlideUpParagraph className="home_summary_content_wrapper">
            <ReactMarkdown>{summaryContent}</ReactMarkdown>
          </SlideUpParagraph>
          <CTALink to={data.content.callToAction.link}>
            {data.content.callToAction.title}
          </CTALink>
        </div>
      </div>
    </div>
  );
};

export { HomePageContent };
