import React from 'react';

const Youtube = () => {
  return (
    <svg width="29" height="22" viewBox="0 0 29 22">
      <g fill="none" fillRule="evenodd">
        <g fill="#CC884D">
          <g>
            <path
              d="M10.987 15.025V5.457l9.585 4.8-9.585 4.768m17.43-10.984c-.41-2.936-3.742-3.634-3.742-3.634-2.78-.53-9.55-.388-10.261-.393-.71.005-7.48-.137-10.261.393 0 0-3.333.698-3.743 3.634 0 0-.923 4.455 0 12.75 0 0 .257 3.073 3.743 3.278 0 0 6.152.46 9.33.41 0 0 .351 0 .93-.004.58.004.931.004.931.004 3.179.05 9.33-.41 9.33-.41 3.487-.205 3.743-3.277 3.743-3.277.923-8.296 0-12.75 0-12.75"
              transform="translate(-1100 -4413) translate(0 4253) translate(1100 160) translate(0 .759)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export { Youtube };
