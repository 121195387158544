import React, { FC } from 'react';
import { ScrollTop, SlidingUPElement } from 'components';

interface FooterProps {
  address: string;
  youtubeLink: string;
  instagramLink: string;
  linkedinLink: string;
  facebookLink: string;
  socialMediaTitle: string;
  mailLink: string;
  landLineNumber: string;
  menu: {
    node: {
      mainNav: {
        title: string;
        slug: string;
        id: number;
      }[];
    };
  }[];
}

const Footer: FC<FooterProps> = ({
  address,
  youtubeLink,
  instagramLink,
  linkedinLink,
  facebookLink,
  socialMediaTitle,
  mailLink,
  landLineNumber,
  menu,
}) => {
  return (
    <footer className="footer">
      <div className="container">
        <SlidingUPElement
          address={address}
          youtubeLink={youtubeLink}
          instagramLink={instagramLink}
          linkedinLink={linkedinLink}
          facebookLink={facebookLink}
          socialMediaTitle={socialMediaTitle}
          mailLink={mailLink}
          landLineNumber={landLineNumber}
          menu={menu}
        />
      </div>
      <ScrollTop />
    </footer>
  );
};

export { Footer };
