import React, { FC, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Link } from 'gatsby';
import { toggleNav } from 'Redux/actions';
import { useSelector, useDispatch } from 'react-redux';

gsap.registerPlugin(ScrollTrigger);
gsap.config({ nullTargetWarn: false });

interface MenuSlideInLeftProps {
  menuIcons: {
    MenuLogo: any;
    LionIcon: any;
  };
  menuContent: string;
  menus: {
    slug: string;
    id: number;
    title: string;
  }[];
}

export const MenuSlideInLeft: FC<MenuSlideInLeftProps> = ({
  menuIcons,
  menuContent,
  menus,
}) => {
  const dispatch = useDispatch();
  const MenubarRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const { isNavOpen } = useSelector((state: any) => {
    return state.NavReducer;
  });
  const [MenubarTimeline] = useState(gsap.timeline({ paused: true }));

  useEffect(() => {
    MenubarTimeline.to(
      '.hamburger-menu .line',
      {
        stroke: '#cc884d',
        duration: 0.3,
      },
      'Hamburger'
    )
      .to(
        '.inspiration-page .hamburger-menu .line',
        {
          stroke: '#fff',
          duration: 0.3,
        },
        'Hamburger'
      )
      .to(
        MenubarRef.current,
        {
          right: 0,
        },
        'Hamburger'
      )
      .from(
        '.nav-item',
        {
          opacity: 0,
          x: 150,
          stagger: 0.1,
          ease: 'Power1.easeOut',
          duration: 1,
        },
        'Hamburger+=0.2'
      )
      .to(
        ['.hamburger-menu .line1', '.hamburger-menu .line3'],
        {
          strokeDasharray: `90 207`,
          strokeDashoffset: -134,
          strokeWidth: 6,
          duration: 0.3,
        },
        'Hamburger'
      )
      .to(
        '.hamburger-menu .line2',
        {
          strokeDasharray: `1 60`,
          strokeDashoffset: -30,
          strokeWidth: 6,
          duration: 0.3,
        },
        'Hamburger'
      )
      .from(
        '.menu-bar-content-wrapper',
        {
          opacity: 0,
          x: -200,
        },
        'Hamburger+=0.2'
      );
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isNavOpen ? MenubarTimeline.play() : MenubarTimeline.reverse();
  }, [isNavOpen]);

  return (
    <>
      <div className="menu-bar" ref={MenubarRef}>
        <div className="menu-bar-container">
          <div className="menu-bar-content-wrapper">
            <div className="menu-bar-title">
              <h1>
                <Link
                  to="/"
                  onClick={() => {
                    setTimeout(() => {
                      dispatch(toggleNav());
                    }, 10);
                  }}
                >
                  <menuIcons.MenuLogo />
                </Link>
              </h1>
            </div>
            <div className="menu-bar-content">
              <div className="menu-bar-content-message">{menuContent}</div>
              <div className="menu-bar-content-icon">
                <menuIcons.LionIcon />
              </div>
            </div>
          </div>
          <nav className="nav-container">
            <ul className="nav-list">
              {menus &&
                menus.map((navItem: any) => {
                  return (
                    <li className="nav-item" key={navItem.id}>
                      <Link
                        to={navItem.slug}
                        onClick={() => {
                          setTimeout(() => {
                            dispatch(toggleNav());
                          }, 10);
                        }}
                      >
                        {navItem.title}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
