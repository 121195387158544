import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface SlideInUpHeadingProps {
  children: any;
  className?: string;
}

export const SlideUpParagraph: FC<SlideInUpHeadingProps> = ({
  children,
  className,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const element: any = ref.current;
    if (element != null) {
      const paragraphs = element.querySelectorAll('p');
      for (let i = 0; i < paragraphs.length; i += 1) {
        gsap.fromTo(
          paragraphs[i],
          { autoAlpha: 0, y: 100 },
          {
            duration: 0.5,
            autoAlpha: 1,
            y: 0,
            scrollTrigger: {
              trigger: paragraphs[i],
              start: 'top center+=50',
              toggleActions: 'play none none reverse',
            },
          }
        );
      }
    }
  }, [ref.current]);

  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
