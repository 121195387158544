import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface CircleExpandInOutProps {
  bannertext: string;
}

export const CircleExpandInOut: FC<CircleExpandInOutProps> = ({
  bannertext,
}) => {
  const circleExpandRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    gsap.fromTo(
      circleExpandRef.current,
      { scale: 0, transformOrigin: 'center' },
      {
        borderRadius: '50%',
        duration: 2,
        scale: 0.9,
        ease: 'power0.easeNone',
        transformOrigin: 'center',
      }
    );
  }, [circleExpandRef.current]);

  return (
    <>
      <div className="blog-detail-page-wrapper-brand" ref={circleExpandRef}>
        <p className="blog-detail-page-wrapper-brand-title">{bannertext}</p>
      </div>
    </>
  );
};
