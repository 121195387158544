import React, { FC, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const SlideInLeftHeading: FC = ({ children }) => {
  const wordsRef = useRef() as React.MutableRefObject<HTMLSpanElement>;

  const HeadingSpanStyle = {
    position: 'relative',
  } as React.CSSProperties;

  React.useEffect(() => {
    const HeadingAnimationPosition = window.innerWidth < 768 ? 0 : 150;
    gsap.fromTo(
      wordsRef.current,
      { left: HeadingAnimationPosition },
      {
        left: '0',
        scrollTrigger: {
          trigger: wordsRef.current,
          scrub: true,
          start: 'top bottom+=50px',
          end: 'bottom top',
        },
      }
    );
  }, []);

  return (
    <span ref={wordsRef} aria-hidden style={HeadingSpanStyle}>
      {children}
    </span>
  );
};
