// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-domain-blog-tsx": () => import("./../../../src/domain/blog.tsx" /* webpackChunkName: "component---src-domain-blog-tsx" */),
  "component---src-domain-contact-us-tsx": () => import("./../../../src/domain/contact-us.tsx" /* webpackChunkName: "component---src-domain-contact-us-tsx" */),
  "component---src-domain-faq-tsx": () => import("./../../../src/domain/faq.tsx" /* webpackChunkName: "component---src-domain-faq-tsx" */),
  "component---src-domain-inspiration-tsx": () => import("./../../../src/domain/inspiration.tsx" /* webpackChunkName: "component---src-domain-inspiration-tsx" */),
  "component---src-domain-jewellery-tsx": () => import("./../../../src/domain/jewellery.tsx" /* webpackChunkName: "component---src-domain-jewellery-tsx" */),
  "component---src-domain-our-brand-tsx": () => import("./../../../src/domain/our-brand.tsx" /* webpackChunkName: "component---src-domain-our-brand-tsx" */),
  "component---src-domain-our-people-tsx": () => import("./../../../src/domain/our-people.tsx" /* webpackChunkName: "component---src-domain-our-people-tsx" */),
  "component---src-domain-our-process-tsx": () => import("./../../../src/domain/our-process.tsx" /* webpackChunkName: "component---src-domain-our-process-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-details-tsx": () => import("./../../../src/templates/blog-details.tsx" /* webpackChunkName: "component---src-templates-blog-details-tsx" */)
}

