/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { FC } from 'react';
import {
  OurPeopleAccordion,
  SlideInRightTitle,
  SlideInLeftTitle,
  RotateIcon,
  VideoPlayer,
} from 'components';
import Img from 'gatsby-image';
import { SingleFlower as Flower } from 'assets/tsx/single-flower';
import { ProudArtisanship } from 'assets/tsx/proud-artisanship';

interface peopleDeatilProps {
  index: number;
  people: {
    id: number;
    name: string;
    description: string;
    image: {
      publicURL: string;
      extension: string;
      childImageSharp: {
        fluid: {
          base64: string;
          aspectRatio: number;
          sizes: string;
          src: string;
          srcSet: string;
        };
      };
    };
    iconName: string;
    peopleAccordion: {
      id: number;
      title: string;
      description: string;
    }[];
  };
}

const OurPeopleDetail: FC<peopleDeatilProps> = ({ people, index }) => {
  const showPeopleIcon =
    people.iconName && people.iconName !== ('none' && null);
  const Icons: any = {
    Flower,
    ProudArtisanship,
  };

  function CreateIcon() {
    const Icon = Icons[people.iconName];
    return <Icon />;
  }
  return (
    <div className="people-list-item">
      <div className="people-list-item-detail-wrapper">
        <div className="people-list-item-detail">
          {people.iconName === 'Flower' ? (
            <RotateIcon
              icon={showPeopleIcon && CreateIcon()}
              classname="people-list-item-detail-icon"
              transformOrigin="50% 100%"
            />
          ) : people.iconName === 'ProudArtisanship' ? (
            <div className="people-list-item-detail-icon">
              <ProudArtisanship />
            </div>
          ) : (
            <RotateIcon
              icon={showPeopleIcon && CreateIcon()}
              classname="people-list-item-detail-icon"
            />
          )}
          <div className="people-list-item-detail-container">
            {index % 2 === 0 ? (
              <SlideInLeftTitle
                title={people.name}
                classname="people-list-item-detail-name"
              />
            ) : (
              <SlideInRightTitle
                title={people.name}
                classname="people-list-item-detail-name"
              />
            )}
            <div className="people-list-item-detail-content">
              {people.description}
            </div>
          </div>
        </div>
        <div className="people-list-item-image">
          {people.image.extension && people.image.extension === 'mp4' ? (
            <VideoPlayer Src={people.image.publicURL} />
          ) : (
            <Img fluid={people.image.childImageSharp.fluid} />
          )}
        </div>
      </div>
      <div className="people-list-item-acccordion-wrapper">
        {people.peopleAccordion.map((accordion: any) => {
          return (
            <OurPeopleAccordion
              accordion={accordion}
              key={`peoplelistaccordion${accordion.id}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export { OurPeopleDetail };
