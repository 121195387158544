import React from 'react';

const MailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="22"
      viewBox="0 0 30 22"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#231F20">
          <path
            d="M26.579 2.382l-.133-.14H3.541c.011.159.093.189.15.244 3.427 3.251 6.85 6.506 10.276 9.757.833.79 1.204.784 2.042-.01 3.386-3.206 6.769-6.413 10.158-9.616.112-.106.273-.157.412-.235zm1.28 1.656c-.26.224-.397.332-.524.453-3.267 3.098-6.534 6.196-9.798 9.298-1.693 1.61-3.403 1.615-5.105-.002-3.22-3.06-6.436-6.124-9.655-9.185-.169-.16-.347-.31-.64-.568v.802l.001 13.27c0 1.373.324 1.703 1.663 1.703h22.355c1.39 0 1.703-.316 1.703-1.725V4.038zM14.938 21.986c-3.786 0-7.572.005-11.358-.003-1.848-.003-2.945-.782-3.439-2.456-.107-.364-.117-.768-.117-1.153C.016 13.456.057 8.539 0 3.622-.022 1.64 1.323-.034 3.521.001c2.784.043 5.569.01 8.353.01 4.848 0 9.695-.005 14.543.002 1.85.003 2.965.793 3.442 2.456.11.384.114.81.114 1.216.007 4.876-.04 9.753.027 14.628.027 2.041-1.324 3.717-3.585 3.687-3.825-.051-7.651-.014-11.477-.014z"
            transform="translate(-738 -2837) translate(738 2834) translate(0 3)"
          />
        </g>
      </g>
    </svg>
  );
};
export { MailIcon };
